import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "../../../../notifications";
import { EditorField, FormCollapsible, DateInput, Select } from "components/forms";
import { createAccountEOBonding, editAccountEOBonding } from "actions";
import { activeAccountIdSelector, activeEOBondingSelector, managementChoicesSelector } from "reducers";
import { partial } from "utils";
import WideSaveButton from "components/buttons/WideSaveButton";
import { eoBondingTypeChoices } from "constants.js";

const EOBondingForm = ({ activeEOBonding, ...props }) => {
  let { document_type, effective_date, expiration_date, notes } = activeEOBonding;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        document_type,
        effective_date,
        expiration_date,
        notes,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isEmpty(activeEOBonding)
          ? props.createAccountEOBonding
          : partial(props.editAccountEOBonding, activeEOBonding.id);

        values.account = props.activeAccountId;

        try {
          await method(values);
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing E&O Bonding");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="E&O Bonding Detail">
            <Form.Row>
              <Select label="Document type" name="document_type" options={eoBondingTypeChoices} />
              <DateInput label="Effective Date" name="effective_date" />
              <DateInput label="Expiration Date" name="expiration_date" />
            </Form.Row>
            <Form.Row>
              <EditorField label="Notes" name="notes" />
            </Form.Row>
            <WideSaveButton submitRef={props.submitRef} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);

  return {
    activeAccountId: activeAccountIdSelector(state),
    activeEOBonding: activeEOBondingSelector(state) || {},
    carrierChoices: choices.carriers,
  };
};

export default connect(mapStateToProps, {
  createAccountEOBonding,
  editAccountEOBonding,
})(EOBondingForm);
