/* Implement a global ModalRoot component that shows/hides modals
 * listening to redux actions. Scales way better than defining modals
 * all over the place, plus each modal is reusable and centralized.
 *
 * Usage:
 *  1) Create the modal component
 *  2) Define a unique MODAL_NAME for the modal in index.js to identify the modal
 *  3) Link the MODAL_NAME and the modal in the object MODAL_COMPONENTS
 *  4) To show the modal, dispatch the action showModal(MODAL_NAME, MODAL_PROPS)
 *  5) To close the modal, dispatch the action hideModal(MODAL_NAME)
 *
 * Taken from stackoverflow's post and modified to work with more than one modal simultaneously:
 *   https://stackoverflow.com/questions/35623656/how-can-i-display-a-modal-dialog-in-redux-that-performs-asynchronous-actions/35641680#35641680
 */

import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import * as modals from "./index";
import { activeModalsSelector } from "reducers";
import ConfirmFileReplaceModal from "./ConfirmFileReplaceModal";
import ConfirmFollowUpReplaceModal from "./ConfirmFollowUpReplaceModal";
import LoadingModal from "./LoadingModal";
import SelectAgencyModal from "./SelectAgencyModal";
import AuctionRoundModal from "../../pages/cases/auction/rounds/RoundModal.js";
import CopyCaseModal from "pages/cases/copyCase/CopyCaseModal.js";
import ConfirmActivityFileUploadModal from "../../pages/cases/auction/activities/ConfirmActivityFileUploadModal";
import AuctionFunderActivityModal from "pages/cases/auction/activities/FunderActivityModal.js";
import AuctionMarketActivityModal from "pages/cases/auction/activities/MarketActivityModal.js";
import ChangeWinningBidModal from "pages/cases/auction/activities/ChangeWinningBidModal";
import SendFunderEmailModal from "pages/cases/auction/emails/SendFunderEmailModal";
import RelatedLifeExpectanciesModal from "pages/cases/insured/RelatedLifeExpectanciesModal";
import CaseFileModal from "pages/cases/caseFiles/CaseFileModal";
import CaseFileModalLE from "pages/cases/caseFiles/CaseFileModalLE";
import CaseFileWarningModal from "pages/cases/caseFiles/CaseFileWarningModal";
import CaseEmailModal from "pages/cases/caseFiles/CaseEmailModal";
import * as deleteModals from "./DeleteModals";
import EmailPreviewModal from "pages/cases/activityLog/EmailPreviewModal";
import CreateProductModal from "pages/productDatabase/CreateProductModal";
import CarrierFileModal from "../../pages/carrier/CarrierFileModal";
import SecurityIntermediaryModal from "../../pages/funders/SecurityIntermediaryModal";
import TradingDocumentModal from "../../pages/funders/TradingDocumentModal";
import PricingDocumentsModal from "../../pages/cases/dataTapes/PricingDocumentsModal";
import RedactModal from "../../pages/cases/caseFiles/redact/RedactModal";
import SendMarketActivityEmailModal from "../../pages/cases/auction/emails/SendMarketActivityEmailModal";
import AccountTaskModal from "../../pages/accounts/information/tasks/AccountTaskModal";
import TaskModal from "../../pages/records/TaskModal";
import LicenseModal from "../../pages/accounts/licensing_and_contracts/licenses/LicenseModal";
import AgreementModal from "../../pages/accounts/licensing_and_contracts/agreements/AgreementModal";
import HierarchyModal from "../../pages/accounts/licensing_and_contracts/hierarchies/HierarchyModal";
import ChangeActiveHierarchyModal from "../../pages/accounts/licensing_and_contracts/hierarchies/ChangeActiveHierarchyModal";
import AccountAffiliationModal from "../../pages/accounts/information/affiliations/AccountAffiliationModal";
import CaseAccountHierarchyModal from "../../pages/cases/detail/CaseAccountHierarchyModal";
import WarningHierarchyModal from "../../pages/accounts/licensing_and_contracts/hierarchies/WarningHierarchyModal";
import SendManagerEmailModal from "../../pages/cases/auction/emails/SendManagerEmailModal";
import FindDoctorModal from "../../pages/cases/caseFiles/doctors/FindDoctorModal";
import CreateAccountModal from "../../pages/accounts/CreateAccountModal";
import ManageEntityModal from "../../pages/accounts/ManageEntityModal";
import APSOrderModal from "../../pages/cases/caseFiles/doctors/APSOrderModal";
import PolicyRequestModal from "../../pages/cases/caseFiles/policy/PolicyRequestModal";
import LeadTaskModal from "../../pages/leads/tasks/LeadTaskModal";
import HierarchySplitModal from "../../pages/accounts/licensing_and_contracts/hierarchies/HierarchySplitModal";
import ParameterModal from "../../pages/riskx/parameters/ParameterModal";
import SynonymModal from "../../pages/riskx/synonyms/SynonymModal";
import CaseTaskModal from "../../pages/cases/caseTasks/CaseTaskModal";
import SendAccountEmailModal from "../../pages/accounts/emails/SendAccountEmailModal";
import BasicCreateAccountModal from "../../pages/accounts/BasicCreateAccountModal";
import ClinicalResearchModal from "../../pages/riskx/parameters/clinicalResearch/ClinicalResearchModal";
import SelectAccountForAdvisorLeadModal from "../../pages/leads/SelectAccountForAdvisorLeadModal";
import QuestHealthRequestModal from "../../pages/cases/caseFiles/quest_health/QuestHealthModal";
import MortalityAssessmentCreationModal from "../../pages/riskx/mortalityAssessments/MortalityAssessmentCreationModal";
import AddConditionModal from "../../pages/riskx/mortalityAssessments/summary/conditionList/AddConditionModal";
import EditConditionListModal from "../../pages/riskx/mortalityAssessments/summary/conditionList/EditConditionListModal";
import SendLeadEmailModal from "../../pages/leads/emails/SendLeadEmailModal";
import CarrierAppointmentModal from "../../pages/accounts/licensing_and_contracts/carrierAppointments/CarrierAppointmentModal";
import RelatedParametersModal from "../../pages/riskx/parameters/RelatedParameterModal";
import EmailTemplateModal from "../../pages/admin/templates/EmailTemplateModal";
import WinFlexModal from "../../pages/cases/winflex/WinFlexModal";
import SendPricingEmailModal from "../../pages/cases/auction/emails/SendPricingEmailModal";
import StateBrokerFileModal from "../../pages/states/StateBrokerFileModal";
import StateProviderFileModal from "../../pages/states/StateProviderFileModal";
import SendSalesRepLeadEmailModal from "../../pages/leads/emails/SendSalesRepLeadEmailModal";
import CashFlowModal from "../../pages/cases/accounting/CashFlowModal";
import IllustrationScrapperModal from "../../pages/cases/illustrationScrapper/IllustrationScrapperModal";
import BackgroundDocumentationModal from "../../pages/accounts/licensing_and_contracts/backgroundDocumentations/BackgroundDocumentationModal";
import EOBondingModal from "../../pages/accounts/licensing_and_contracts/eoBondings/EOBondingModal";
import { DeleteAccountBackgroundDocumentation, DeleteAccountBackgroundDocumentationModal } from "./DeleteModals";

const MODAL_COMPONENTS = {
  [modals.LOADING_MODAL]: LoadingModal,
  [modals.CONFIRM_FILE_REPLACE_MODAL]: ConfirmFileReplaceModal,
  [modals.CONFIRM_FOLLOW_UP_REPLACE_MODAL]: ConfirmFollowUpReplaceModal,
  [modals.CONFIRM_FILE_DELETE_MODAL]: deleteModals.ConfirmFileDeleteModal,
  [modals.DELETE_INSURED_MODAL]: deleteModals.DeleteInsuredModal,
  [modals.DELETE_OWNER_PERSON_MODAL]: deleteModals.DeleteOwnerPersonModal,
  [modals.DELETE_OWNER_ENTITY_MODAL]: deleteModals.DeleteOwnerEntityModal,
  [modals.DELETE_OWNER_INSURED_MODAL]: deleteModals.DeleteOwnerInsuredModal,
  [modals.DELETE_CASE_MODAL]: deleteModals.DeleteCaseModal,
  [modals.COPY_CASE_MODAL]: CopyCaseModal,
  [modals.PRICING_DOCUMENTS_MODAL]: PricingDocumentsModal,
  [modals.CASE_FILE_MODAL]: CaseFileModal,
  [modals.FIND_DOCTOR_MODAL]: FindDoctorModal,
  [modals.APS_ORDER_MODAL]: APSOrderModal,
  [modals.POLICY_REQUEST_MODAL]: PolicyRequestModal,
  [modals.QUEST_HEALTH_REQUEST_MODAL]: QuestHealthRequestModal,
  [modals.REDACT_MODAL]: RedactModal,
  [modals.CASE_FILE_MODAL_LE]: CaseFileModalLE,
  [modals.CASE_FILE_WARNING_MODAL]: CaseFileWarningModal,
  [modals.DELETE_CASE_FILE_MODAL]: deleteModals.DeleteCaseFileModal,
  [modals.DELETE_LIFE_EXPECTANCY_MODAL]: deleteModals.DeleteLifeExpectancyModal,
  [modals.SELECT_AGENCY_MODAL]: SelectAgencyModal,
  [modals.DELETE_OWNER_SIGNER_MODAL]: deleteModals.DeleteOwnerSignerModal,
  [modals.AUCTION_ROUND_MODAL]: AuctionRoundModal,
  [modals.DELETE_AUCTION_ROUND_MODAL]: deleteModals.DeleteAuctionRoundModal,
  [modals.AUCTION_FUNDER_ACTIVITY_MODAL]: AuctionFunderActivityModal,
  [modals.AUCTION_MARKET_ACTIVITY_MODAL]: AuctionMarketActivityModal,
  [modals.DELETE_FUNDER_AUCTION_ACTIVITY_MODAL]: deleteModals.DeleteFunderAuctionActivityModal,
  [modals.DELETE_MARKET_AUCTION_ACTIVITY_MODAL]: deleteModals.DeleteMarketAuctionActivityModal,
  [modals.DELETE_AUCTION_ACTIVITY_FILE_MODAL]: deleteModals.DeleteAuctionActivityFileModal,
  [modals.CONFIRM_AUCTION_ACTIVITY_FILE_UPLOAD]: ConfirmActivityFileUploadModal,
  [modals.AUCTION_ACTIVITY_CHANGE_WINNING_BID_MODAL]: ChangeWinningBidModal,
  [modals.DELETE_BENEFICIARY_MODAL]: deleteModals.DeleteBeneficiaryModal,
  [modals.DELETE_BENEFICIARY_SIGNER_MODAL]: deleteModals.DeleteBeneficiarySignerModal,
  [modals.SEND_FUNDER_EMAIL_MODAL]: SendFunderEmailModal,
  [modals.SEND_MANAGER_EMAIL_MODAL]: SendManagerEmailModal,
  [modals.SEND_PRICING_EMAIL_MODAL]: SendPricingEmailModal,
  [modals.SEND_MARKET_ACTIVITY_EMAIL_MODAL]: SendMarketActivityEmailModal,
  [modals.CASE_EMAIL_MODAL]: CaseEmailModal,
  [modals.RELATED_LE_MODAL]: RelatedLifeExpectanciesModal,
  [modals.EMAIL_PREVIEW_MODAL]: EmailPreviewModal,
  [modals.PRODUCT_MODAL]: CreateProductModal,
  [modals.DELETE_PRODUCT_MODAL]: deleteModals.DeleteProductModal,
  [modals.CARRIER_FILE_MODAL]: CarrierFileModal,
  [modals.STATE_BROKER_FILE_MODAL]: StateBrokerFileModal,
  [modals.STATE_PROVIDER_FILE_MODAL]: StateProviderFileModal,
  [modals.SECURITY_INTERMEDIARY_MODAL]: SecurityIntermediaryModal,
  [modals.TRADING_DOCUMENT_MODAL]: TradingDocumentModal,
  [modals.DELETE_CARRIER_FILE_MODAL]: deleteModals.DeleteCarrierFileModal,
  [modals.DELETE_PRODUCT_TEMPLATE_MODAL]: deleteModals.DeleteProductTemplateModal,
  [modals.DELETE_CARRIER_MODAL]: deleteModals.DeleteCarrierModal,
  [modals.DELETE_STATE_MODAL]: deleteModals.DeleteStateModal,
  [modals.DELETE_STATE_BROKER_FILE_MODAL]: deleteModals.DeleteStateBrokerFileModal,
  [modals.DELETE_STATE_PROVIDER_FILE_MODAL]: deleteModals.DeleteStateProviderFileModal,
  [modals.DELETE_FUNDER_MODAL]: deleteModals.DeleteFunderModal,
  [modals.DELETE_FUNDER_CONTACT_MODAL]: deleteModals.DeleteFunderContactModal,
  [modals.DELETE_FUNDER_ENTITY_MODAL]: deleteModals.DeleteFunderEntityModal,
  [modals.DELETE_SECURITY_INTERMEDIARY_MODAL]: deleteModals.DeleteSecurityIntermediaryModal,
  [modals.DELETE_TRADING_DOCUMENT_MODAL]: deleteModals.DeleteTradingDocumentModal,
  [modals.DELETE_ACCOUNT_MODAL]: deleteModals.DeleteAccountModal,
  [modals.DELETE_CASH_FLOW_MODAL]: deleteModals.DeleteCashFlowModal,
  [modals.ACCOUNT_TASK_MODAL]: AccountTaskModal,
  [modals.ACCOUNT_CARRIER_APPOINTMENT_MODAL]: CarrierAppointmentModal,
  [modals.ACCOUNT_BACKGROUND_DOCUMENTATION_MODAL]: BackgroundDocumentationModal,
  [modals.ACCOUNT_EO_BONDING_MODAL]: EOBondingModal,
  [modals.ACCOUNT_LICENSE_MODAL]: LicenseModal,
  [modals.ACCOUNT_AGREEMENT_MODAL]: AgreementModal,
  [modals.ACCOUNT_HIERARCHY_MODAL]: HierarchyModal,
  [modals.ACCOUNT_HIERARCHY_SPLIT_MODAL]: HierarchySplitModal,
  [modals.ACCOUNT_CHANGE_ACTIVE_HIERARCHY_MODAL]: ChangeActiveHierarchyModal,
  [modals.ACCOUNT_WARNING_UPDATE_HIERARCHY_MODAL]: WarningHierarchyModal,
  [modals.ACCOUNT_AFFILIATION_MODAL]: AccountAffiliationModal,
  [modals.CASE_ACCOUNT_HIERARCHY_MODAL]: CaseAccountHierarchyModal,
  [modals.BASIC_CREATE_ACCOUNT_MODAL]: BasicCreateAccountModal,
  [modals.CREATE_ACCOUNT_MODAL]: CreateAccountModal,
  [modals.MANAGE_ENTITY_MODAL]: ManageEntityModal,
  [modals.DELETE_ACCOUNT_TASK_MODAL]: deleteModals.DeleteAccountTaskModal,
  [modals.DELETE_ACCOUNT_CARRIER_APPOINTMENT_MODAL]: deleteModals.DeleteAccountCarrierAppointmentModal,
  [modals.DELETE_ACCOUNT_BACKGROUND_DOCUMENTATION_MODAL]: deleteModals.DeleteAccountBackgroundDocumentationModal,
  [modals.DELETE_ACCOUNT_EO_BONDING_MODAL]: deleteModals.DeleteAccountEOBondingModal,
  [modals.DELETE_ACCOUNT_LICENSE_MODAL]: deleteModals.DeleteAccountLicenseModal,
  [modals.DELETE_ACCOUNT_AGREEMENT_MODAL]: deleteModals.DeleteAccountAgreementModal,
  [modals.DELETE_ACCOUNT_HIERARCHY_MODAL]: deleteModals.DeleteAccountHierarchyModal,
  [modals.DELETE_ACCOUNT_HIERARCHY_SPLIT_MODAL]: deleteModals.DeleteAccountHierarchySplitModal,
  [modals.DELETE_ACCOUNT_AFFILIATION_MODAL]: deleteModals.DeleteAccountAffiliationModal,
  [modals.DELETE_LEAD_MODAL]: deleteModals.DeleteLeadModal,
  [modals.DELETE_LEAD_TASK_MODAL]: deleteModals.DeleteLeadTaskModal,
  [modals.LEAD_TASK_MODAL]: LeadTaskModal,
  [modals.SELECT_ACCOUNT_FOR_ADVISOR_LEAD_MODAL]: SelectAccountForAdvisorLeadModal,
  [modals.PARAMETER_MODAL]: ParameterModal,
  [modals.RELATED_PARAMETERS_MODAL]: RelatedParametersModal,
  [modals.DELETE_PARAMETER_MODAL]: deleteModals.DeleteParameterModal,
  [modals.REMOVE_RELATED_PARAMETER_MODAL]: deleteModals.RemoveRelatedParameterModal,
  [modals.SYNONYM_MODAL]: SynonymModal,
  [modals.DELETE_SYNONYM_MODAL]: deleteModals.DeleteSynonymModal,
  [modals.DELETE_MORTALITY_ASSESSMENT_MODAL]: deleteModals.DeleteMortalityAssessmentModal,
  [modals.TASK_MODAL]: TaskModal,
  [modals.CASE_TASK_MODAL]: CaseTaskModal,
  [modals.DELETE_CASE_TASK_MODAL]: deleteModals.DeleteCaseTaskModal,
  [modals.CLINICAL_RESEARCH_MODAL]: ClinicalResearchModal,
  [modals.MORTALITY_ASSESSMENT_CREATION_MODAL]: MortalityAssessmentCreationModal,
  [modals.ADD_CONDITION_MODAL]: AddConditionModal,
  [modals.EDIT_CONDITION_LIST_MODAL]: EditConditionListModal,
  [modals.DELETE_CONDITION_LIST_MODAL]: deleteModals.DeleteConditionListModal,
  [modals.SEND_ACCOUNT_EMAIL_MODAL]: SendAccountEmailModal,
  [modals.SEND_LEAD_EMAIL_MODAL]: SendLeadEmailModal,
  [modals.SEND_SALES_REP_LEAD_EMAIL_MODAL]: SendSalesRepLeadEmailModal,
  [modals.DELETE_CLINICAL_RESEARCH_MODAL]: deleteModals.DeleteClinicalResearchModal,
  [modals.EMAIL_TEMPLATE_MODAL]: EmailTemplateModal,
  [modals.DELETE_EMAIL_TEMPLATE_MODAL]: deleteModals.DeleteEmailTemplateModal,
  [modals.WINFLEX_ILLUSTRATION_MODAL]: WinFlexModal,
  [modals.CASH_FLOW_MODAL]: CashFlowModal,
  [modals.ILLUSTRATION_SCRAPPER_MODAL]: IllustrationScrapperModal,
};

const ModalRoot = ({ activeModals }) => {
  if (isEmpty(activeModals)) {
    return null;
  }

  return activeModals.map(({ modalType, modalProps }, i) => {
    const SpecificModal = MODAL_COMPONENTS[modalType];
    return <SpecificModal key={i} {...modalProps} />;
  });
};

const mapStateToProps = state => {
  return { activeModals: activeModalsSelector(state) };
};

export default connect(mapStateToProps)(ModalRoot);
