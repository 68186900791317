import React from "react";
import { connect } from "react-redux";
import { IconContext } from "react-icons";
import { AiOutlineDelete } from "react-icons/ai";

import { showModal, downloadFile } from "actions";

import IconButton from "components/IconButton";
import { formatBytes } from "../utils";
import { Col } from "react-bootstrap";
import FileIcon from "./FileIcon";

const FileLocal = ({ file, ...props }) => {
  if (file === null || !file?.name) {
    return null;
  }
  return (
    <Col md={4} className="file-display">
      <div className="file-icon">
        <IconContext.Provider value={{ size: "2rem" }}>
          <FileIcon fileName={file.name} />
        </IconContext.Provider>
      </div>
      <div className="file-details">
        <div className="file-name">{file.name}</div>
        <div className="size-and-actions">
          <small className="text-muted">{formatBytes(file.size)}</small>
          <div className="action-icons">
            <div className="icon">
              <IconButton
                Icon={AiOutlineDelete}
                onClick={props.deleteFile(file)}
                iconConfig={{ size: "1.2rem" }}
                title="Delete File"
              />
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default connect(null, { showModal, downloadFile })(FileLocal);
