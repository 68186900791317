import { DELETE_CASH_FLOW, FETCH_CASH_FLOW, SELECT_CASH_FLOW } from "actions/types";

export const defaultState = {
  cashFlow: [],
  activeCashFlowId: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_CASH_FLOW:
      return { ...state, cashFlow: action.payload };
    case SELECT_CASH_FLOW:
      return { ...state, activeCashFlowId: action.payload };
    case DELETE_CASH_FLOW:
      delete state.cashFlow[action.payload.id];
      return { ...state, activeCashFlowId: defaultState.activeCashFlowId };
    default:
      return state;
  }
};

// Selectors
export const cashFlowSelector = state => state.cashFlow.filter(cf => cf.type !== "Total");

export const totalCashFlowSelector = state => state.cashFlow.find(cf => cf.type === "Total");

export const activeCashFlowSelector = ({ cashFlow, activeCashFlowId }) => {
  if (activeCashFlowId) {
    return cashFlow.find(cf => cf.id === activeCashFlowId);
  }
};
