import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { fetchDoctors, resetAPSOrderFiles, saveDoctor, selectDoctor, showModal } from "actions";
import { Collapsible, EditorField, TextInput } from "components/forms";
import { FaFirstAid } from "react-icons/fa";
import { MdSend } from "react-icons/md";
import { APS_ORDER_MODAL, FIND_DOCTOR_MODAL } from "components/modals";
import { activeCaseFileSelector, doctorSelector } from "reducers";

const DoctorDetailForm = ({ ...props }) => {
  let initialValues = {
    ...props.doctorInfo,
    ...props.activeCaseFile?.doctor,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        // This is done via handleClick
      }}
    >
      {({ handleSubmit, values }) => {
        const handleClick = () => {
          if (values.npi) {
            props.saveDoctor(values);
          }
        };
        const npiUndefined = values.npi === undefined;

        function renderDoctorData() {
          return (
            <Form onSubmit={handleSubmit}>
              <Collapsible
                title={"NPI Doctor Data (read-only)"}
                style={{ marginBottom: 0, paddingBottom: 0 }}
                initiallyHidden={npiUndefined}
              >
                <Form.Row>
                  <TextInput label="Doctor's Name" name="npi_name" disabled />
                  <TextInput label="National Provider Identifier" name="npi" disabled />
                  <TextInput label="Specialty" name="npi_specialty" disabled />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Street Address" name="npi_street_address" disabled />
                  <TextInput label="City" name="npi_city" disabled />
                  <TextInput label="State" name="npi_state" disabled />
                  <TextInput label="Zip Code" name="npi_zip_code" disabled />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Enumeration Date" name="npi_enumeration_date" disabled />
                  <TextInput label="Phone Number" name="npi_phone_number" disabled />
                  <TextInput label="Fax Number" name="npi_fax_number" disabled />
                </Form.Row>
                <hr />
              </Collapsible>
              <Collapsible title={"LifeRoc Doctor Data"} initiallyHidden={npiUndefined}>
                <Form.Row>
                  <TextInput label="Street Address" name="street_address" />
                  <TextInput label="City" name="city" />
                  <TextInput label="State" name="state" />
                  <TextInput label="Zip Code" name="zipcode" />
                </Form.Row>
                <Form.Row>
                  <TextInput label="E-mail" lowercase={true} name="email" />
                  <TextInput label="Phone Number" name="phone" />
                  <TextInput label="Fax Number" name="fax" />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Payment Method" name="payment_method" />
                  <TextInput label="Medical Records Person" name="medical_records_person" />
                  <TextInput label="Delivery Method" name="delivery_method" />
                  <TextInput label="Copy Service" name="copy_service" />
                </Form.Row>
                <Form.Row>
                  <EditorField label="Notes" name="notes" />
                </Form.Row>
                <button type="button" ref={props.submitRef} onClick={handleClick} style={{ display: "none" }} />
              </Collapsible>
            </Form>
          );
        }

        return (
          <>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
              <button
                className={`btn btn--secondary`}
                style={{ backgroundColor: "blue", marginRight: "10px" }}
                onClick={() => {
                  props.showModal(FIND_DOCTOR_MODAL);
                }}
              >
                <FaFirstAid />
                Find Doctor
              </button>
              <button
                className={`btn btn--secondary`}
                onClick={() => {
                  props.submitRef && props.submitRef.current.click();
                  props.resetAPSOrderFiles();
                  props.showModal(APS_ORDER_MODAL);
                }}
                disabled={!initialValues?.npi}
              >
                <MdSend />
                Start APS Order
              </button>
            </div>
            {values.npi ? renderDoctorData() : null}
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  let activeCaseFile = activeCaseFileSelector(state);
  return {
    activeCaseFile,
    doctorInfo: doctorSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  saveDoctor,
  fetchDoctors,
  selectDoctor,
  resetAPSOrderFiles,
})(DoctorDetailForm);
