import React from "react";
import { connect, useDispatch } from "react-redux";
import { Card } from "react-bootstrap";

import { ButtonCreate, ButtonExcelDownload, ButtonSendTo } from "components/buttons";
import { RecordsTable, RecordsTableTabs } from "components/table";
import {
  AUCTION_ROUND_MODAL,
  AUCTION_MARKET_ACTIVITY_MODAL,
  SEND_FUNDER_EMAIL_MODAL,
  AUCTION_FUNDER_ACTIVITY_MODAL,
  CASH_FLOW_MODAL,
} from "components/modals";
import {
  selectAuctionRound,
  selectAuctionActivity,
  fetchAuction,
  showModal,
  downloadFile,
  setShouldSave,
  unsetShouldSave,
  fetchLightFunders,
  fetchInsured,
  selectCashFlow,
} from "actions";
import FunderActivitiesTable from "./activities/FunderActivitiesTable";
import MarketActivitiesTable from "./activities/MarketActivitiesTable";
import RoundsTable from "./rounds/RoundsTable";
import FundersAuctionTable from "./FundersAuctionTable";
import {
  activeCaseSelector,
  auctionAccessEnabledSelector,
  auctionIdSelector,
  loadingSelector,
  selectedFundersSelector,
  shouldSaveSelector,
  funderSearchStringSelector,
  activeCaseIdSelector,
} from "reducers";
import * as types from "actions/types";
import Loader from "components/Loader";
import { SET_EMAIL_TEMPLATE } from "actions/types";
import { getViewMode } from "../../../utils";
import AuctionNotesForm from "./form/AuctionNotesForm";
import AccountingForm from "../accounting/AccountingForm";
import CashFlowTable from "../accounting/CashFlowTable";
import { isAdmin } from "../../../permissions";

const AuctionPage = ({ hasAdminPermission, funderSearchString, ...props }) => {
  const dispatch = useDispatch();
  const isViewMode = getViewMode();

  React.useEffect(() => {
    if (props.auctionAccessEnabled) {
      props.fetchLightFunders();
    }
  }, []);

  React.useEffect(() => {
    if (props.activeCaseId) {
      props.fetchInsured(props.activeCaseId);
    }
  }, [props.activeCaseId]);

  React.useEffect(() => {
    if (props.caseInfo.submission_id) {
      document.title = `LifeRoc | ${props.caseInfo.submission_id} | Trading`;
    }
  }, [props.caseInfo.id]);

  const auctionNotesFormSubmitRef = React.useRef();
  const accountingFormSubmitRef = React.useRef();
  React.useEffect(() => {
    if (props.shouldSave) {
      setTimeout(() => {
        auctionNotesFormSubmitRef.current.click();
      }, 500);
      setTimeout(() => {
        accountingFormSubmitRef.current.click();
      }, 700);
      setTimeout(() => {
        props.unsetShouldSave();
      }, 1000);
    }
  }, [props.shouldSave]);

  if (props.loading.error404) {
    return <></>;
  }

  if (!props.auctionAccessEnabled) {
    return null;
  }
  const allowedBiddingFormats = ["LPEX", "BROKER AUCTION"];
  const roundstable = allowedBiddingFormats.includes(props.caseInfo?.bidding_format);

  let marketActivityActions = [
    <ButtonExcelDownload
      onClick={() =>
        props.downloadFile(
          `/auctions/api/market_activities/${props.auctionId}/download_excel/`,
          "market_activities_report.csv",
        )
      }
      disabled={props.auctionId === undefined}
    >
      Download Summary
    </ButtonExcelDownload>,
  ];

  let funderActivityActions = [
    <input
      type="text"
      defaultValue={funderSearchString}
      placeholder="Search Funder"
      style={{ borderRadius: 2, padding: "0 4px" }}
      onChange={e => dispatch({ type: types.FUNDER_SEARCH_STRING, payload: e.target.value })}
    />,
  ];

  if (!isViewMode) {
    marketActivityActions.push(
      <ButtonCreate
        onClick={() => {
          props.selectAuctionActivity(null);
          props.showModal(AUCTION_MARKET_ACTIVITY_MODAL);
        }}
      >
        Add Market Activity
      </ButtonCreate>,
    );
    funderActivityActions.push(
      <ButtonCreate
        onClick={() => {
          props.selectAuctionActivity(null);
          props.showModal(AUCTION_FUNDER_ACTIVITY_MODAL);
        }}
        style={{ marginLeft: "5px" }}
      >
        Add Funder Activity
      </ButtonCreate>,
    );
  }

  return (
    <>
      <AccountingForm submitRef={accountingFormSubmitRef} />
      <RecordsTable
        title="Cash Flows"
        createButtonLabel={isViewMode ? "" : "Add Cash Flow"}
        buttonDisabled={!hasAdminPermission}
        onCreateClick={() => {
          props.selectCashFlow(null);
          props.showModal(CASH_FLOW_MODAL);
        }}
        Table={CashFlowTable}
      />
      <Card>
        <Card.Body>
          <AuctionNotesForm submitRef={auctionNotesFormSubmitRef} />
        </Card.Body>
      </Card>
      <Loader isLoading={props.loading.auction} text={"Loading Auction data"} />
      {roundstable ? (
        <RecordsTable
          title="Round Detail"
          createButtonLabel="Add Round"
          onCreateClick={() => {
            props.selectAuctionRound(null);
            props.showModal(AUCTION_ROUND_MODAL);
          }}
          Table={RoundsTable}
        />
      ) : (
        ""
      )}
      <RecordsTable
        title="Market Activity"
        onCreateClick={() => {
          props.selectAuctionRound(null);
          props.selectAuctionActivity(null);
          props.showModal(AUCTION_MARKET_ACTIVITY_MODAL);
        }}
        Table={MarketActivitiesTable}
        actionButtons={marketActivityActions}
      />
      <RecordsTableTabs
        onCreateClick={() => {}}
        Table1={FunderActivitiesTable}
        Table2={FundersAuctionTable}
        table2props={funderSearchString}
        actionButtons1={funderActivityActions}
        actionButtons2={[
          <input
            type="text"
            defaultValue={funderSearchString}
            placeholder="Search Funder"
            style={{ borderRadius: 2, padding: "0 4px" }}
            onChange={e => dispatch({ type: types.FUNDER_SEARCH_STRING, payload: e.target.value })}
          />,
          <ButtonSendTo
            onClick={() => {
              dispatch({
                type: SET_EMAIL_TEMPLATE,
                payload: "Funder New Case Notification",
              });
              props.showModal(SEND_FUNDER_EMAIL_MODAL);
            }}
            key="create"
            style={{ marginLeft: "5px" }}
            disabled={props.selectedFunders.length === 0}
          >
            {props.selectedFunders.length === 0
              ? "Select Funders to Send"
              : `Send to Funders (${props.selectedFunders.length})`}
          </ButtonSendTo>,
        ]}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    activeCaseId: activeCaseIdSelector(state),
    loading: loadingSelector(state),
    auctionAccessEnabled: auctionAccessEnabledSelector(state),
    selectedFunders: selectedFundersSelector(state),
    auctionId: auctionIdSelector(state),
    shouldSave: shouldSaveSelector(state),
    funderSearchString: funderSearchStringSelector(state),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  selectAuctionRound,
  selectAuctionActivity,
  showModal,
  downloadFile,
  fetchAuction,
  fetchLightFunders,
  fetchInsured,
  setShouldSave,
  unsetShouldSave,
  selectCashFlow,
})(AuctionPage);
