import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as Yup from "yup";

import * as notifications from "../../../notifications";
import {
  BooleanSelect,
  CurrencyInput,
  Select,
  TextInput,
  DateInput,
  SubmitButton,
  StateSelect,
} from "components/forms";
import { editPolicy, fetchLifeSettlementCase, fetchProductChoices, validateNumberOfInsured } from "actions";
import {
  managementChoicesSelector,
  policySelector,
  numberOfBeneficiariesSelector,
  productChoicesSelector,
  numberOfOwnersSelector,
  activeCaseSelector,
} from "reducers";
import { floatWithoutCommas } from "utils";
import { isAdmin } from "permissions";
import CarrierProductSelect from "./CarrierProductSelect";
import Collapsible from "../../../components/Collapsible";
import { debtBenefitChoices, rateClassChoices, tableRateChoices } from "../../../constants";

const PolicyForm = ({ policy, hasAdminPermission, ...props }) => {
  const { carrier } = policy;

  React.useEffect(() => {
    console.log("useEffect carrier is", carrier);
    if (carrier) {
      props.fetchProductChoices(carrier);
    }
  }, [carrier]);

  const onCarrierChange = value => {
    if (value) {
      props.fetchProductChoices(value);
    } else {
      console.log("no carrier selected");
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        insured_1_gender: props.insured_1_gender,
        insured_2_gender: props.insured_2_gender,
        ...policy,
      }}
      validationSchema={Yup.object({
        face_amount: Yup.number()
          .transform((o, v) => floatWithoutCommas(v))
          .typeError("Amount must be a number")
          .positive("Amount must be greater than zero")
          .nullable(),
      })}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          await props.editPolicy(policy.id, values);
          // Re-fetch the case
          props.fetchLifeSettlementCase(props.caseInfo.id);
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing policy");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <Form onSubmit={handleSubmit} className={"form shadowed"} {...props}>
            <Collapsible title="Policy detail">
              <Form.Row>
                <TextInput label="Policy Number" name="policy_number" disabled={!hasAdminPermission} />
                <CurrencyInput label="Face Amount" name="face_amount" disabled={!hasAdminPermission} />
                <DateInput label="Policy Effective Date" name="policy_date" disabled={!hasAdminPermission} />
                <DateInput label="Policy Issue Date" name="policy_issue_date" disabled={!hasAdminPermission} />
              </Form.Row>
              <CarrierProductSelect
                values={values}
                carrierChoices={props.carrierChoices}
                onCarrierChange={onCarrierChange}
                productChoices={props.productChoices}
              />
              {values.product_number_of_insured_lives && (
                <Form.Row>
                  <TextInput label="Insured 1 Gender" name="insured_1_gender" disabled />
                  <Select
                    label="Insured 1 Rate Class"
                    name="insured_1_rate_class"
                    placeholder="Select Insured 1 Rate Class"
                    options={rateClassChoices}
                    disabled={!hasAdminPermission}
                  />
                  <BooleanSelect
                    label="Insured 1 Tobacco"
                    name="insured_1_tobacco_use"
                    placeholder="Select Insured 1 Tobacco Use"
                    disabled={!hasAdminPermission}
                  />
                  <Select
                    label="Insured 1 Table Rating"
                    name="insured_1_table_rate"
                    placeholder="Not Table Rated"
                    options={tableRateChoices}
                    disabled={!hasAdminPermission}
                  />
                </Form.Row>
              )}
              {values.product_number_of_insured_lives === 2 && (
                <Form.Row>
                  <TextInput label="Insured 2 Gender" name="insured_2_gender" disabled />
                  <Select
                    label="Insured 2 Rate Class"
                    name="insured_2_rate_class"
                    placeholder="Select Insured 2 Rate Class"
                    options={rateClassChoices}
                    disabled={!hasAdminPermission}
                  />
                  <BooleanSelect
                    label="Insured 2 Tobacco"
                    name="insured_2_tobacco_use"
                    placeholder="Select Insured 2 Tobacco Use"
                    disabled={!hasAdminPermission}
                  />
                  <Select
                    label="Insured 2 Table Rating"
                    name="insured_2_table_rate"
                    placeholder="Not Table Rated"
                    options={tableRateChoices}
                    disabled={!hasAdminPermission}
                  />
                </Form.Row>
              )}
              <Form.Row>
                <StateSelect label="State Issued" name="state_issued" md={3} disabled={!hasAdminPermission} />
                <TextInput label="Policy Status" name="status" md={3} disabled={!hasAdminPermission} />
                <Select
                  label="DB Option"
                  name="debt_benefit"
                  placeholder="Select DB option"
                  options={debtBenefitChoices}
                  md={3}
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <Form.Row>
                <BooleanSelect
                  label="Accidental D&D"
                  name="accidental_death_benefits"
                  md={2}
                  disabled={!hasAdminPermission}
                />
                <TextInput
                  label="Accidental D&D Detail"
                  name="accidental_death_benefits_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
                <BooleanSelect
                  label="ADB / Terminal Illness:"
                  name="adb_terminal_illness"
                  md={2}
                  disabled={!hasAdminPermission}
                />
                <TextInput
                  label="ADB / Terminal Illness Detail"
                  name="adb_terminal_illness_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <Form.Row>
                <BooleanSelect label="Charity Rider" name="charity_rider" md={2} disabled={!hasAdminPermission} />
                <TextInput
                  label="Charity Rider Detail"
                  name="charity_rider_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
                <BooleanSelect label="Child Rider" name="child_rider" md={2} disabled={!hasAdminPermission} />
                <TextInput
                  label="Child Rider Detail"
                  name="child_rider_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <Form.Row>
                <BooleanSelect
                  label="Chronic Illness"
                  name="chronic_illness_benefits"
                  md={2}
                  disabled={!hasAdminPermission}
                />
                <TextInput
                  label="Chronic Illness Detail"
                  name="chronic_illness_benefits_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
                <BooleanSelect
                  label="Conversion Rights"
                  name="conversion_rights"
                  md={2}
                  disabled={!hasAdminPermission}
                />
                <TextInput
                  label="Conversion Rights Detail"
                  name="conversion_rights_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <Form.Row>
                <BooleanSelect
                  label="Disability Income"
                  name="disability_income"
                  md={2}
                  disabled={!hasAdminPermission}
                />
                <TextInput
                  label="Disability Income Detail"
                  name="disability_income_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
                <BooleanSelect
                  label="Guaranteed Insurability"
                  name="guaranteed_insurability_options"
                  md={2}
                  disabled={!hasAdminPermission}
                />
                <TextInput
                  label="Guaranteed Insurability Detail"
                  name="guaranteed_insurability_options_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <Form.Row>
                <BooleanSelect
                  label="Long-Term Care"
                  name="long_term_care_benefits"
                  md={2}
                  disabled={!hasAdminPermission}
                />
                <TextInput
                  label="Long-Term Care Detail"
                  name="long_term_care_benefits_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
                <BooleanSelect
                  label="Policy Split Option"
                  name="policy_split_option"
                  md={2}
                  disabled={!hasAdminPermission}
                />
                <TextInput
                  label="Policy Split Detail"
                  name="policy_split_option_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <Form.Row>
                <BooleanSelect label="ROP Rider" name="rop_rider" md={2} disabled={!hasAdminPermission} />
                <TextInput
                  label="ROP Rider Detail"
                  name="rop_rider_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
                <BooleanSelect label="Spousal Rider" name="spousal_rider" md={2} disabled={!hasAdminPermission} />
                <TextInput
                  label="Spousal Rider Detail"
                  name="spousal_rider_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <Form.Row>
                <BooleanSelect
                  label="Waiver of Premium"
                  name="waiver_of_premium_benefits"
                  md={2}
                  disabled={!hasAdminPermission}
                />
                <TextInput
                  label="Waiver of Premium Detail"
                  name="waiver_of_premium_benefits_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
            </Collapsible>
            <Collapsible title="Policy History">
              <Form.Row>
                <BooleanSelect
                  label="Result of Term Conversion"
                  name="is_conversion_result"
                  disabled={!hasAdminPermission}
                  md={3}
                />
                <TextInput
                  label="Original Term Policy Number"
                  name="original_term_policy_number"
                  disabled={!values.is_conversion_result || !hasAdminPermission}
                  md={4}
                />
                <DateInput
                  label="Original Term Policy Date"
                  name="original_term_policy_date"
                  disabled={!values.is_conversion_result || !hasAdminPermission}
                  md={5}
                />
              </Form.Row>
              <Form.Row>
                <BooleanSelect
                  label="Premium Financed"
                  name="is_premium_financed"
                  md={3}
                  disabled={!hasAdminPermission}
                />
                <TextInput
                  label="Financing Detail"
                  name="is_premium_financed_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <Form.Row>
                <BooleanSelect label="Ever Lapsed" name="ever_lapsed" md={3} disabled={!hasAdminPermission} />
                <TextInput label="Lapse Detail" name="ever_lapsed_description" md={4} disabled={!hasAdminPermission} />
              </Form.Row>
              <Form.Row>
                <BooleanSelect label="Ever Reinstated" name="ever_reinstated" md={3} disabled={!hasAdminPermission} />
                <TextInput
                  label="Reinstatement Detail"
                  name="ever_reinstated_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <Form.Row>
                <BooleanSelect
                  label="COI Ever Increased"
                  name="coi_ever_increased"
                  md={3}
                  disabled={!hasAdminPermission}
                />
                <TextInput
                  label="COI Increase Detail"
                  name="coi_ever_increased_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <Form.Row>
                <BooleanSelect
                  label="Beneficial Interest"
                  name="beneficial_interest"
                  md={3}
                  disabled={!hasAdminPermission}
                />
                <TextInput
                  label="Beneficial Interest Detail"
                  name="beneficial_interest_description"
                  md={4}
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <Form.Row>
                <StateSelect
                  label="Original Settlement State"
                  name="original_settlement_state"
                  disabled={!hasAdminPermission}
                  md={3}
                />
                <BooleanSelect
                  label="S&C Period Active"
                  name="s_c_period_active"
                  placeholder="Select Yes/No"
                  disabled={!hasAdminPermission}
                  md={4}
                />
                <DateInput
                  label="Original Settlement Date"
                  name="original_settlement_date"
                  disabled={!hasAdminPermission}
                  md={5}
                />
              </Form.Row>
            </Collapsible>
            <Collapsible title="Policy Values">
              <Form.Row>
                <DateInput label="Values Date" name="values_date" disabled={!hasAdminPermission} />
                <CurrencyInput label="Cash Value" name="cash_value" disabled={!hasAdminPermission} />
              </Form.Row>
              <Form.Row>
                <CurrencyInput label="Loan Balance" name="outstanding_loan_balance" disabled={!hasAdminPermission} />
                <CurrencyInput
                  label="Cash Surrender Value"
                  name="cash_surrender_value"
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
            </Collapsible>
            <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const caseInfo = activeCaseSelector(state);

  let insured_1_gender = "";
  let insured_2_gender = "";
  if (caseInfo.insured_genders) {
    let insured_genders = caseInfo.insured_genders.split(",");
    if (insured_genders.length === 1) {
      insured_1_gender = insured_genders[0].trim().replace("F", "Female").replace("M", "Male");
    } else if (insured_genders.length === 2) {
      insured_1_gender = insured_genders[0].trim();
      insured_2_gender = insured_genders[1].trim();
      insured_1_gender = insured_1_gender.replace("F", "Female").replace("M", "Male");
      insured_2_gender = insured_2_gender.replace("F", "Female").replace("M", "Male");
    }
  }

  return {
    caseInfo,
    policy: policySelector(state),
    productChoices: productChoicesSelector(state),
    carrierChoices: choices.carriers,
    numberOfOwners: numberOfOwnersSelector(state),
    numberOfBeneficiaries: numberOfBeneficiariesSelector(state),
    insured_1_gender,
    insured_2_gender,
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  editPolicy,
  validateNumberOfInsured,
  fetchProductChoices,
  fetchLifeSettlementCase,
})(PolicyForm);
