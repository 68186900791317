import {
  SELECT_CASE_FILE,
  FETCH_CASE_FILES,
  EDIT_CASE_FILE,
  EDIT_MULTIPLE_CASE_FILES,
  CREATE_CASE_FILE,
  DELETE_CASE_FILE,
  DELETE_CASE_FILE_FILE,
  STORE_CASE_FILE_CATEGORY,
  CLEAR_CASE_FILE_CATEGORY,
  STORE_CASE_FILE_DESCRIPTION,
  CLEAR_CASE_FILE_DESCRIPTION,
  SELECT_DOCTOR,
  FETCH_CASE_FILE,
  CLEAR_CASE_FILES,
  FETCH_ORDER_FILES,
  RESET_ORDER_FILES,
  CLEAR_ACTIVE_CASE_FILE,
  UPDATE_POLICY_REQUEST_OPTION,
  EDIT_ILLUSTRATION_TABLE,
} from "actions/types";
import { arrayToObjectIndexedById } from "reducers/utils";
import { removeKey } from "../../../utils";

export const defaultState = {
  caseFiles: {},
  activeCaseFile: null,
  category: "",
  description: "",
  doctor: {},
  activeOrderFiles: {},
  policyRequestOptions: {},
  illustrationTable: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_CASE_FILE:
      return {
        ...state,
        activeCaseFile: action.payload,
        doctor: {},
      };

    case FETCH_CASE_FILES: {
      let caseFiles = arrayToObjectIndexedById(action.payload);
      const mergedCaseFiles = { ...caseFiles, ...state.caseFiles };
      return { ...state, caseFiles: mergedCaseFiles };
    }

    case CLEAR_CASE_FILES: {
      return { ...state, caseFiles: [] };
    }

    case CLEAR_ACTIVE_CASE_FILE: {
      return { ...state, activeCaseFile: null };
    }

    case FETCH_CASE_FILE: {
      let newState = state;
      newState.caseFiles[action.payload.id] = action.payload;
      return newState;
    }

    case EDIT_MULTIPLE_CASE_FILES: {
      let new_state = {
        ...state,
      };

      if (action.payload.data[0].action === "change_status") {
        for (const case_data of action.payload.data) {
          new_state.caseFiles[case_data.id].status = case_data.status;
        }
      } else if (action.payload.data[0].action === "change_follow_up_date") {
        for (const case_data of action.payload.data) {
          new_state.caseFiles[case_data.id].follow_up_date = case_data.follow_up_date;
        }
      } else if (action.payload.data[0].action === "delete") {
        for (const case_data of action.payload.data) {
          delete new_state.caseFiles[case_data.id];
        }
      }
      return new_state;
    }
    case EDIT_CASE_FILE:
    case CREATE_CASE_FILE: {
      return {
        ...state,
        caseFiles: {
          ...state.caseFiles,
          [action.payload.id]: action.payload,
        },
        activeCaseFile: action.payload,
      };
    }

    case DELETE_CASE_FILE: {
      return {
        ...state,
        caseFiles: removeKey(state.caseFiles, action.payload),
      };
    }
    case DELETE_CASE_FILE_FILE: {
      return {
        ...state,
        caseFiles: {
          ...state.caseFiles,
          [action.payload.id]: action.payload,
        },
      };
    }
    case STORE_CASE_FILE_CATEGORY: {
      return {
        ...state,
        category: action.payload,
      };
    }
    case CLEAR_CASE_FILE_CATEGORY: {
      return {
        ...state,
        category: defaultState.category,
      };
    }

    case STORE_CASE_FILE_DESCRIPTION: {
      return {
        ...state,
        description: action.payload,
      };
    }

    case SELECT_DOCTOR:
      return {
        ...state,
        doctor: action.payload,
      };

    case FETCH_ORDER_FILES:
      return {
        ...state,
        activeOrderFiles: action.payload,
      };

    case UPDATE_POLICY_REQUEST_OPTION:
      return {
        ...state,
        policyRequestOptions: {
          ...state.policyRequestOptions,
          ...action.payload,
        },
      };

    case RESET_ORDER_FILES:
      return {
        ...state,
        activeOrderFiles: null,
      };

    case CLEAR_CASE_FILE_DESCRIPTION: {
      return {
        ...state,
        description: defaultState.description,
      };
    }

    case EDIT_ILLUSTRATION_TABLE: {
      return {
        ...state,
        illustrationTable: action.payload.data,
      };
    }

    default:
      return state;
  }
};

export const activeCaseFileSelector = ({ caseFiles, activeCaseFile }) => {
  if (activeCaseFile) {
    // If it has id, the row already exists in the database and we should pick it from the state
    if (activeCaseFile.id) {
      return caseFiles[activeCaseFile.id];
      // Otherwise it is a placeholder row and we just use the same data
    } else {
      return activeCaseFile;
    }
  }
  return null;
};

export const caseFilesSelector = state => {
  return Object.values(state.caseFiles);
};

export const numberOfPendingCaseFilesSelector = state => {
  let pendingCases = Object.entries(state.caseFiles).filter(([key, value]) => value.status < 20);
  return pendingCases.length;
};

export const caseFilesCategorySelector = state => {
  return state.category;
};

export const caseFilesDescriptionSelector = state => {
  return state.description;
};

export const doctorInfoSelector = state => {
  return state.doctor;
};

export const activeOrderFilesSelector = state => state.activeOrderFiles;

export const policyRequestOptionsSelector = state => state.policyRequestOptions;

export const illustrationTableSelector = state => state.illustrationTable;
