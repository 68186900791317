import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import { RecordsTable } from "components/table";
import {
  ACCOUNT_AGREEMENT_MODAL,
  ACCOUNT_BACKGROUND_DOCUMENTATION_MODAL,
  ACCOUNT_CARRIER_APPOINTMENT_MODAL,
  ACCOUNT_EO_BONDING_MODAL,
  ACCOUNT_HIERARCHY_MODAL,
  ACCOUNT_LICENSE_MODAL,
} from "components/modals";
import {
  showModal,
  selectAccountAgreement,
  selectAccountLicense,
  selectAccountHierarchy,
  fetchLifeSettlementChoices,
  fetchManagerAgents,
  selectAccountCarrierAppointment,
  selectAccountBackgroundDocumentation,
  selectAccountEOBonding,
} from "actions";
import LicenseTable from "./licenses/LicenseTable";
import AgreementTable from "./agreements/AgreementTable";
import AccountLicenseForm from "./AccountLicenseForm";
import { activeAccountSelector } from "reducers";
import HierarchyTable from "./hierarchies/HierarchyTable";
import CarrierAppointmentTable from "./carrierAppointments/CarrierAppointmentTable";
import EOBondingTable from "./eoBondings/EOBondingTable";
import BackgroundDocumentationTable from "./backgroundDocumentations/BackgroundDocumentationTable";

const AccountLicensingPage = ({ accountInfo, ...props }) => {
  React.useEffect(() => {
    props.fetchManagerAgents();
    props.fetchLifeSettlementChoices();
    if (accountInfo && accountInfo.name) {
      document.title = `LifeRoc | ${accountInfo.name} | Account Licensing & Contracts`;
    }
  }, []);

  return (
    <div className={`section-card shadowed case-section`} style={{ marginTop: "5.5rem" }}>
      <Card className={"case-info"}>
        <Card.Body>
          <AccountLicenseForm accountInfo={accountInfo} />
          <RecordsTable
            title="Background Documentation"
            createButtonLabel="Add Background Documentation"
            onCreateClick={() => {
              props.selectAccountBackgroundDocumentation(null);
              props.showModal(ACCOUNT_BACKGROUND_DOCUMENTATION_MODAL);
            }}
            Table={BackgroundDocumentationTable}
          />
          <RecordsTable
            title="Carrier Appointments"
            createButtonLabel="Add Carrier Appointment"
            onCreateClick={() => {
              props.selectAccountCarrierAppointment(null);
              props.showModal(ACCOUNT_CARRIER_APPOINTMENT_MODAL);
            }}
            Table={CarrierAppointmentTable}
          />
          <RecordsTable
            title="E&O Bonding"
            createButtonLabel="Add E&O Bonding"
            onCreateClick={() => {
              props.selectAccountEOBonding(null);
              props.showModal(ACCOUNT_EO_BONDING_MODAL);
            }}
            Table={EOBondingTable}
          />
          <RecordsTable
            title="Licenses"
            createButtonLabel="Add License"
            onCreateClick={() => {
              props.selectAccountLicense(null);
              props.showModal(ACCOUNT_LICENSE_MODAL);
            }}
            Table={LicenseTable}
          />
          <RecordsTable
            title="Sales Hierarchies"
            createButtonLabel="Add Hierarchy"
            onCreateClick={() => {
              props.selectAccountHierarchy(null);
              props.showModal(ACCOUNT_HIERARCHY_MODAL);
            }}
            Table={HierarchyTable}
          />
          <RecordsTable
            title="Sourcing Agreements"
            createButtonLabel="Add Agreement"
            onCreateClick={() => {
              props.selectAccountAgreement(null);
              props.showModal(ACCOUNT_AGREEMENT_MODAL);
            }}
            Table={AgreementTable}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    accountInfo: activeAccountSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectAccountCarrierAppointment,
  selectAccountBackgroundDocumentation,
  selectAccountEOBonding,
  selectAccountLicense,
  selectAccountAgreement,
  selectAccountHierarchy,
  fetchLifeSettlementChoices,
  fetchManagerAgents,
})(AccountLicensingPage);
