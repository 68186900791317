import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import PDFViewer from "../../../components/pdf/PDFViewer";
import { activeCaseFileSelector, illustrationTableSelector } from "reducers";
import { Form } from "react-bootstrap";
import { Collapsible, Select, TextInput } from "components/forms";
import FormCollapsible from "../../../components/forms/FormCollapsible";
import ReactDataSheet from "react-datasheet";
import { makeChoices, partition } from "../../../utils";
import { AddNewRowButton, cellRenderer } from "../lifeExpectancies/MortalityTable";
import { FaArrowLeft, FaTable } from "react-icons/fa";
import { MdSend } from "react-icons/md";
import { extractIllustrationTable, updateOptimizationCalculator } from "actions";
import * as notifications from "notifications";
import SpinLoader from "../../../components/SpinLoader";

const IllustrationScrapperForm = props => {
  const COLUMNS = 5;
  let [step, setStep] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = React.useState(props.sheetRows);
  const [fullTableRows, setFullTableRows] = React.useState(props.fullTableRows);

  React.useEffect(() => {
    setRows(props.sheetRows);
  }, [props.sheetRows]);

  React.useEffect(() => {
    setFullTableRows(props.fullTableRows);
  }, [props.fullTableRows]);

  return (
    <>
      <Formik
        initialValues={{
          column_1: "Year",
          column_2: "Premium",
          column_3: "Account Value",
          column_4: "Cash Surrender Value",
          column_5: "Death Benefits",
        }}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          console.log("onSubmit", values);
        }}
      >
        {({ handleSubmit, values, setFieldValue, setErrors }) => {
          const ExtractTableButton = () => {
            const onClick = async () => {
              try {
                setLoading(true);
                await props.extractIllustrationTable(props.activeCaseFile.id, values.pages);
                setStep(2);
              } catch (error) {
                notifications.error("Error Extracting Table");
                setErrors(error.response.data);
              } finally {
                setLoading(false);
              }
            };

            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="btn btn--secondary"
                  onMouseDown={onClick}
                  disabled={loading}
                  style={{ height: 38, width: 160 }}
                >
                  {loading ? <SpinLoader /> : <FaTable />}
                  {loading ? "Extracting..." : "Extract Table"}
                </button>
              </div>
            );
          };

          const Step2Buttons = () => {
            return (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    setStep(1);
                  }}
                  style={{ width: "10%", margin: "1rem" }}
                >
                  <FaArrowLeft />
                  Back
                </button>
                <button
                  className="btn btn--secondary"
                  onClick={async () => {
                    setLoading(true); // Disable the button
                    try {
                      const response = await props.updateOptimizationCalculator(props.activeCaseFile.id, values, rows);
                      const linkMessage = (
                        <div>
                          Optimization Calculator updated successfully. Review it&nbsp;
                          <a
                            href={response.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "lightblue", textDecoration: "underline" }}
                          >
                            here
                          </a>
                        </div>
                      );
                      notifications.info(linkMessage);
                    } catch (e) {
                      notifications.error(e);
                    } finally {
                      setLoading(false);
                    }
                  }}
                  style={{ width: "30%", margin: "1rem" }}
                  disabled={loading}
                >
                  {loading ? <SpinLoader /> : <MdSend />}
                  {loading ? "Saving values..." : "Save values to Optimization Calculator"}
                </button>
              </div>
            );
          };

          if (step === 1) {
            return (
              <FormCollapsible title={"Scrapping Details"} onSubmit={handleSubmit}>
                <Form.Row>
                  <div style={{ marginLeft: 10 }}>
                    <h5>Instructions</h5>
                    <b>Step 1.</b> Find the start of the Tabular pages in the document below
                    <br />
                    <b>Step 2.</b> Enter the number of page or pages as comma separated values to use for the scrapping
                    <br />
                    <b>Step 3.</b> Click Extract Table, results will appear after ~30 seconds
                    <br />
                    <br />
                  </div>
                </Form.Row>
                <Form.Row>
                  <TextInput label="Tabular Page(s)" name="pages" md={4} autoFocus={true} isRequired={true} />
                  <ExtractTableButton />
                </Form.Row>
              </FormCollapsible>
            );
          } else if (step === 2) {
            return (
              <>
                <FormCollapsible title={"Extracted Tabular Table"} onSubmit={handleSubmit}>
                  <Form.Row>
                    <ReactDataSheet
                      data={rows}
                      className="shadowed"
                      valueRenderer={cell =>
                        // Format as currency for values >= 100
                        cell.value >= 100 ? `$ ${Number(cell.value).toLocaleString("en-US")}` : cell.value
                      }
                      cellRenderer={cellRenderer}
                      onCellsChanged={(changes, additions = []) => {
                        // update current rows (changes)
                        const updatedRows = [...rows];
                        changes.forEach(({ row, col, value }) => {
                          updatedRows[row][col].value = value;
                        });

                        // add the new rows (additions)
                        const newRows = partition(additions, COLUMNS);

                        const allRows = [...updatedRows, ...newRows];
                        setRows(allRows);
                      }}
                    />
                  </Form.Row>
                  <Form.Row>
                    <AddNewRowButton rows={rows} setRows={setRows} rowSize={COLUMNS} />
                  </Form.Row>
                  <Step2Buttons />
                </FormCollapsible>
                <Collapsible title={"Full Table Data"} initiallyHidden={true}>
                  <Form.Row>
                    <ReactDataSheet
                      data={fullTableRows}
                      className="shadowed"
                      valueRenderer={cell =>
                        // Format as currency for values >= 100
                        cell.value >= 100 ? `$ ${Number(cell.value).toLocaleString("en-US")}` : cell.value
                      }
                      cellRenderer={cellRenderer}
                      onCellsChanged={(changes, additions = []) => {
                        // update current rows (changes)
                        const updatedRows = [...rows];
                        changes.forEach(({ row, col, value }) => {
                          updatedRows[row][col].value = value;
                        });

                        // add the new rows (additions)
                        const newRows = partition(additions, COLUMNS);

                        const allRows = [...updatedRows, ...newRows];
                        setFullTableRows(allRows);
                      }}
                    />
                  </Form.Row>
                  <Form.Row>
                    {props.extractionDuration ? <p>Extracted in {props.extractionDuration}s</p> : null}
                  </Form.Row>
                </Collapsible>
              </>
            );
          }
        }}
      </Formik>
      <PDFViewer fileUrl={props.activeCaseFile?.file?.url} />
    </>
  );
};

const mapStateToProps = state => {
  const activeCaseFile = activeCaseFileSelector(state);
  const illustrationScrapperData = illustrationTableSelector(state);
  const COLUMN_TYPES = makeChoices([
    "Year",
    "Premium",
    "Account Value",
    "Cash Surrender Value",
    "Death Benefits",
    "IGNORE COLUMN",
  ]);

  let sheetRows = [];
  let fullTableRows = [];
  let firstRow = [
    <Select name="column_1" options={COLUMN_TYPES} defaultValue={COLUMN_TYPES[0]} />,
    <Select name="column_2" options={COLUMN_TYPES} defaultValue={COLUMN_TYPES[1]} />,
    <Select name="column_3" options={COLUMN_TYPES} defaultValue={COLUMN_TYPES[2]} />,
    <Select name="column_4" options={COLUMN_TYPES} defaultValue={COLUMN_TYPES[3]} />,
    <Select name="column_5" options={COLUMN_TYPES} defaultValue={COLUMN_TYPES[4]} />,
  ];
  sheetRows.push(
    firstRow.map(title => {
      return { value: title, readOnly: true, className: "title" };
    }),
  );

  const estimatedResult = illustrationScrapperData?.estimated_result;
  const fullTable = illustrationScrapperData?.full_table;
  const textRaw = illustrationScrapperData?.text_raw;

  if (estimatedResult && estimatedResult.length > 1) {
    sheetRows.push(
      estimatedResult[0].map(title => {
        return { value: title, readOnly: true, className: "title" };
      }),
    );

    for (let i = 1; i < estimatedResult.length; i++) {
      let row = estimatedResult[i];
      sheetRows.push(row.map(value => ({ value })));
    }
  }

  if (fullTable && fullTable.length > 1) {
    fullTableRows.push(
      fullTable[0].map(title => {
        return { value: title, readOnly: true, className: "title" };
      }),
    );

    for (let i = 1; i < fullTable.length; i++) {
      let row = fullTable[i];
      fullTableRows.push(row.map(value => ({ value })));
    }
  }

  return {
    activeCaseFile,
    sheetRows,
    fullTableRows,
    textRaw,
    extractionDuration: illustrationScrapperData?.duration,
  };
};

export default connect(mapStateToProps, {
  extractIllustrationTable,
  updateOptimizationCalculator,
})(IllustrationScrapperForm);
