import React from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import * as notifications from "../../../notifications";
import { Collapsible, EditorField, Select, SubmitButton } from "components/forms";
import { Form } from "react-bootstrap";
import { activeCaseSelector, managementChoicesSelector } from "reducers";
import { connect } from "react-redux";
import { editCase, showModal } from "actions";
import { isAdmin } from "../../../permissions";

const AccountingForm = ({ caseInfo, hasAdminPermission, ...props }) => {
  if (!caseInfo) {
    // Still didn't fetch data
    return null;
  }

  const { accounting_notes } = caseInfo;
  return (
    <Formik
      enableReinitialize
      initialValues={{
        accounting_notes,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          await props.editCase(caseInfo.id, values);
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing case");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <>
            <Form onSubmit={handleSubmit}>
              <Collapsible title="Case Accounting" style={{ marginBottom: 0, paddingBottom: 0 }}>
                <EditorField
                  style={{ width: "100%" }}
                  inline={false}
                  name="accounting_notes"
                  initial={accounting_notes}
                  disabled={!hasAdminPermission}
                />
              </Collapsible>
              <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const activeCase = activeCaseSelector(state);

  return {
    caseInfo: activeCase,
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  editCase,
})(AccountingForm);
