import advisorhq from "apis/advisorhq";
import {
  AUTH_ERROR,
  LOADING,
  LOGIN_ATTEMPT,
  LOGIN_CANCEL,
  LOGIN_FIRST_STEP_SUCCESSFUL,
  LOGIN_SECOND_STEP_SUCCESSFUL,
  LOGOUT,
  RESET_PASSWORD,
  SELECT_AGENCY,
  SET_PASSWORD,
  SMS_ERROR,
} from "./types";
import { URLS } from "../constants";
import { redirectTo } from "../utils";

const TOKEN_KEY = "ahq_jwt_token";

export const getRedirectUrl = () => {
  return window.location.pathname.includes("/next=") ? window.location.pathname.split("/next=")[1] : URLS.TASKS;
};

/* Authentication using an email and password.
If successful we will request SMS confirmation
 */
export const loginFirstStep = formValues => async dispatch => {
  dispatch({ type: LOADING, payload: { login: true } });
  dispatch({ type: LOGIN_ATTEMPT });
  const { email, password } = formValues;
  try {
    const response = await advisorhq.post("/api-token-auth/", { email, password });

    // Expected response has "two_factor_active" or "qr_code_url"
    // in development, two_factor is skipped when skip_two_factor is returned
    if (!response.data.skip_two_factor && (response.data.two_factor_active || response.data.qr_code_url)) {
      dispatch({ type: LOADING, payload: { login: false } });
      await dispatch({
        type: LOGIN_FIRST_STEP_SUCCESSFUL,
        payload: {
          email: formValues.email,
          password: formValues.password,
          two_factor_active: response.data.two_factor_active,
          qr_code_url: response.data.qr_code_url,
        },
      });
    } else if (response.data.token) {
      localStorage.setItem(TOKEN_KEY, response.data.token);
      await dispatch(getCaseManagementEnabledAgencies(formValues.email));
      dispatch({ type: LOADING, payload: { login: false } });
      redirectTo(getRedirectUrl());
    }
  } catch (error) {
    dispatch({ type: LOADING, payload: { login: false } });
    let errorMessage = error.response.data;
    if (typeof errorMessage === "string") {
      dispatch({ type: AUTH_ERROR, payload: [defaultErrorMessage] });
    } else {
      dispatch({ type: AUTH_ERROR, payload: errorMessage });
    }
  }
};

/* Authentication using email, password and sms code
Once the user is authenticated, we retrieve its case management enabled agencies.
 */
export const loginSecondStep = formValues => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: { login: true } });
    const response = await advisorhq.post("/api-token-auth/", formValues);
    localStorage.setItem(TOKEN_KEY, response.data.token);
    redirectTo(getRedirectUrl());
    return await dispatch(getCaseManagementEnabledAgencies(formValues.email));
  } catch (error) {
    dispatch({ type: SMS_ERROR, payload: error.response.data });
  }
  dispatch({ type: LOADING, payload: { login: false } });
};

export const loginCancel = () => async dispatch => {
  dispatch({ type: LOADING, payload: { login: false } });
  dispatch({ type: LOGIN_CANCEL });
};

/* Reset user password using email. */
export const resetPassword = formValues => async dispatch => {
  dispatch({ type: RESET_PASSWORD });
  return await advisorhq.post("/reset-password/", formValues);
};

/* Set user password with tokens */
export const setPassword = (formValues, uid, reset_password_token) => async dispatch => {
  dispatch({ type: SET_PASSWORD });
  return await advisorhq.post("/set-password/", {
    uid,
    reset_password_token,
    ...formValues,
  });
};

/* Get the enabled case management agencies for the current logged in user.
 *
 * At least one enabled agency is required for login. When there are no agencies,
 * the api will return a 400 response, and we dispatch the errors.
 *
 * If there is only one available agency, select it.
 * If there is a previously selected agency, remember it.
 */
const defaultErrorMessage = "Unable to establish connection with the server. Please try again later.";
export const getCaseManagementEnabledAgencies = () => async dispatch => {
  let enabledAgencies = true;
  let response;

  try {
    response = await advisorhq.get("/life_settlement/case-management-enabled-agencies/");
  } catch (error) {
    enabledAgencies = false;
    if (!error.response) {
      dispatch({
        type: AUTH_ERROR,
        payload: [defaultErrorMessage],
      });
    } else {
      let errorMessage = error.response.data;
      if (typeof errorMessage === "string") {
        dispatch({ type: AUTH_ERROR, payload: [defaultErrorMessage] });
      } else {
        dispatch({ type: AUTH_ERROR, payload: errorMessage });
      }
    }
  }

  if (enabledAgencies) {
    const agencies = response.data.agencies;
    const email = response.data.user;
    const user_id = response.data.user_id;
    const user_name = response.data.user_name;
    const user_phone = response.data.user_phone;
    const user_fax = response.data.user_fax;
    const user_title = response.data.user_title;
    const user_license = response.data.user_license;
    const user_calendly_link = response.data.user_calendly_link;
    const user_winflex_email = response.data.user_winflex_email;
    const active_agency = response.data.active_agency;
    const active_agency_roles = response.data.active_agency_roles;

    await dispatch({
      type: LOGIN_SECOND_STEP_SUCCESSFUL,
      payload: {
        agencies,
        email,
        user_id,
        user_name,
        user_phone,
        user_fax,
        user_title,
        user_license,
        user_calendly_link,
        user_winflex_email,
        active_agency,
        active_agency_roles,
      },
    });
    return true;
  }
};

export const selectAgency = agencyData => {
  return { type: SELECT_AGENCY, payload: agencyData };
};

export const logout = url => async dispatch => {
  dispatch({ type: LOGOUT });
  const redirectUrl = (typeof url === "string" && url) || URLS.LOGIN;
  redirectTo(redirectUrl);
  window.location.pathname = redirectUrl;
  localStorage.removeItem(TOKEN_KEY);
};
