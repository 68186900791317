import React from "react";
import { connect } from "react-redux";

import { FaDownload, FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { showModal, downloadFile, selectAccountEOBonding } from "actions";
import { activeAccountSelector } from "reducers";
import { ACCOUNT_EO_BONDING_MODAL, DELETE_ACCOUNT_EO_BONDING_MODAL } from "components/modals";
import Table, { choiceCell, dateCell, datetimeCell, notesCell } from "components/table";
import { IconLinkViewOrEdit, IconLinkDelete } from "components/icons";
import { eoBondingTypeChoices } from "../../../../constants";
import IconLink from "../../../../components/IconLink";
import { isAdmin } from "../../../../permissions";

const EOBondingTable = ({ activeAccount, hasAdminPermission, ...props }) => {
  if (!activeAccount) {
    // Still didn't fetch data
    return null;
  }

  const columns = [
    {
      Header: "Action",
      className: "action",
      Cell: ({ row }) => {
        const id = row.original.id;
        const file = row.original.file;
        console.log(row.original);
        return (
          <>
            <IconLinkViewOrEdit
              onClick={() => {
                props.selectAccountEOBonding(row.original);
                props.showModal(ACCOUNT_EO_BONDING_MODAL);
              }}
            />
            {hasAdminPermission && (
              <IconLinkDelete
                onClick={() => {
                  props.showModal(DELETE_ACCOUNT_EO_BONDING_MODAL, { id });
                }}
              />
            )}
            {file ? (
              <>
                <IconLink
                  Icon={FaDownload}
                  iconConfig={{ className: "table--action-icon" }}
                  tooltip="Download"
                  onClick={() => {
                    const url = `/api/eo_bonding/${id}/download_file/`;
                    props.downloadFile(url, row.original.file.name);
                  }}
                />
                <IconLink
                  Icon={FaEye}
                  iconConfig={{ className: "table--action-icon" }}
                  tooltip="Preview"
                  onClick={() => window.open(row.original.file.url, "_blank")}
                />
              </>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Document Type",
      accessor: "document_type",
      Cell: choiceCell(eoBondingTypeChoices),
    },
    {
      Header: "Effective Date",
      accessor: "effective_date",
      Cell: dateCell,
    },
    {
      Header: "Expiration Date",
      accessor: "expiration_date",
      Cell: dateCell,
    },
    {
      Header: "Notes",
      accessor: "notes",
      Cell: notesCell,
    },
    {
      Header: "Last Edited By",
      accessor: "last_edited_by_name",
    },
    {
      Header: "Last Edited Date",
      accessor: "last_edit_date",
      Cell: datetimeCell,
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.eo_bonding}
      defaultSort={true}
      paginated={false}
      emptyMessage="No Carrier Appointments found"
    />
  );
};

const mapStateToProps = state => {
  const activeAccount = activeAccountSelector(state);

  return {
    activeAccount,
    eo_bonding: activeAccount ? activeAccount.eo_bonding : [],
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectAccountEOBonding,
  downloadFile,
})(EOBondingTable);
