import React from "react";
import { useField } from "formik";
import { Form, InputGroup, Col } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import FieldErrors from "./FieldErrors";
import FieldHelpText from "./FieldHelpText";
import Label from "./Label";
import { getValidationClassName } from "./validation";
import { getViewMode } from "../../utils";

/* Percentage Input field
 *
 * The stored value is in float format, applying the given ratio to convert
 * to and from the given value.
 *
 * Internally it has 2 inputs, one visible that holds the user input
 * and one hidden that converts the entered value to a float value.
 */
export default ({
  label,
  name,
  md = "",
  isRequired = false,
  ratio = 100, // Ratio to apply to the input value.
  helpText,
  decimalLimit = 3,
  ...props
}) => {
  const [field, meta, { setValue }] = useField({ name });
  const isViewMode = getViewMode();
  const isDisabled = props.disabled || isViewMode;

  function percentageMask(rawValue) {
    let numberMask = createNumberMask({
      allowDecimal: true,
      integerLimit: 3, // up to 3 digits for the integer part
      decimalLimit: 3, // up to 3 digits for the decimal part
      prefix: "",
      includeThousandsSeparator: true,
      requireDecimal: true,
      allowLeadingZeroes: false,
    });
    let mask = numberMask(rawValue);

    let decimalsRegex = /\.([0-9]{1,3})/;
    let result = decimalsRegex.exec(rawValue);
    // In case there is only one decimal
    if (!result) {
      mask.push("000");
    } else if (result[1].length === 1) {
      mask.push("00");
    } else if (result[1].length === 2) {
      mask.push("0");
    }
    mask.push(" %");

    return mask;
  }

  const handleChange = value => {
    if (value === "") {
      setValue(null);
    } else {
      let cleanedValue = value.replace(" %", "");
      cleanedValue = parseFloat(cleanedValue);
      setValue(Number(cleanedValue) / ratio);
    }
  };

  const maskValue = field.value === null || field.value === undefined || isNaN(field.value) ? "" : field.value * ratio;

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      <input type="hidden" {...field} value="" />
      <InputGroup style={{ flexWrap: "no-wrap!important" }}>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <div style={{ marginLeft: 1, marginRight: 3 }}>{label}</div>
            {props.icon}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <div style={{ flexGrow: "1" }}>
          <MaskedInput
            mask={percentageMask}
            value={maskValue}
            className={`form-control ${getValidationClassName(meta) || ""}`}
            autoComplete="off"
            onChange={e => handleChange(e.target.value)}
            {...props}
            disabled={isDisabled}
          />
        </div>
        <FieldErrors meta={meta} displayError={true} />
      </InputGroup>
      {helpText && <FieldHelpText>{helpText}</FieldHelpText>}
    </Form.Group>
  );
};
