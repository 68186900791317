export const EMAIL_TEMPLATE_TYPES = {
  ACCOUNT: 1,
  CASE: 2,
  FUNDER_CASE: 3,
  LEAD: 4,
  FUNDER: 5,
};

export const EMAIL_TEMPLATE_TYPE_NAMES = {
  [EMAIL_TEMPLATE_TYPES.ACCOUNT]: "Account",
  [EMAIL_TEMPLATE_TYPES.CASE]: "Case",
  [EMAIL_TEMPLATE_TYPES.FUNDER]: "Funder",
  [EMAIL_TEMPLATE_TYPES.FUNDER_CASE]: "Funder Case",
  [EMAIL_TEMPLATE_TYPES.LEAD]: "Lead",
};
