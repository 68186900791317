import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { CurrencyInput, DateInput, EditorField, FormCollapsible, Select, SubmitButton } from "components/forms";
import { activeCaseSelector, activeCashFlowSelector, shouldSaveSelector } from "reducers";
import { Form } from "react-bootstrap";
import { createCashFlow, unsetShouldSave, selectSynonym, showModal, editCashFlow } from "actions";
import { partial } from "utils";
import * as notifications from "notifications";
import {
  cashFlowDirectionChoices,
  cashFlowEntitiesChoices,
  cashFlowStatusChoices,
  cashFlowTypesChoices,
} from "./constants";
import { isAdmin } from "../../../permissions";

const CashFlowForm = ({ caseInfo, cashFlow, ...props }) => {
  if (!caseInfo) {
    // Still didn't fetch data
    return null;
  }

  const initialValues = {
    ...cashFlow,
    caseId: caseInfo && caseInfo.id,
    cashFlow: cashFlow && cashFlow.id,
  };

  const submitRef = React.useRef();

  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        let method = partial(props.createCashFlow, caseInfo.id);
        if (values.cashFlow) {
          method = partial(props.editCashFlow, caseInfo.id, values.cashFlow);
        }

        try {
          await method(values);
          notifications.success("State saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing State");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        let title = "New Cash Flow";
        if (cashFlow) {
          title = "Edit Cash Flow";
        }
        return (
          <FormCollapsible
            onSubmit={handleSubmit}
            title={title}
            className={"form" + "shadowed"}
            disabled={!props.hasAdminPermission}
            {...props}
          >
            <Form.Row>
              <Select
                label="Type"
                name="type"
                options={cashFlowTypesChoices}
                onChangeCallback={option => {
                  if (option?.direction) {
                    setFieldValue("direction", option.direction);
                  }
                }}
                md={4}
              />
              <Select label="Direction" name="direction" options={cashFlowDirectionChoices} md={4} />
              <CurrencyInput label="Amount" name="amount" md={4} />
            </Form.Row>
            <Form.Row>
              <Select label="Status" name="status" options={cashFlowStatusChoices} md={4} />
              <Select label="Entity" name="entity" options={cashFlowEntitiesChoices} md={4} />
              <DateInput label="Date" name="date" md={4} />
            </Form.Row>
            <Form.Row>
              <EditorField label="Notes" name="notes" />
            </Form.Row>
            <SubmitButton innerRef={submitRef} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    shouldSave: shouldSaveSelector(state),
    caseInfo: activeCaseSelector(state),
    cashFlow: activeCashFlowSelector(state),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  createCashFlow,
  editCashFlow,
  unsetShouldSave,
  selectSynonym,
  showModal,
})(CashFlowForm);
