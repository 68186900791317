import React from "react";
import { Form } from "react-bootstrap";

import { isInvalid } from "./validation";

const FieldErrors = ({ meta, displayError }) => {
  const style = displayError ? { display: "block" } : {};

  return (
    <Form.Control.Feedback type="invalid" style={style}>
      {isInvalid(meta) && meta.error}
    </Form.Control.Feedback>
  );
};

export default FieldErrors;
