import React from "react";
import { connect } from "react-redux";

import {
  showModal,
  selectAccountHierarchy,
  selectSplit,
  editAccountHierarchySplit,
  editAccountHierarchyAsASplit,
} from "actions";
import {
  activeAccountIdSelector,
  activeAccountSelector,
  activeHierarchySelector,
  managementChoicesSelector,
} from "reducers";
import {
  ACCOUNT_CHANGE_ACTIVE_HIERARCHY_MODAL,
  ACCOUNT_HIERARCHY_SPLIT_MODAL,
  ACCOUNT_WARNING_UPDATE_HIERARCHY_MODAL,
  DELETE_ACCOUNT_HIERARCHY_SPLIT_MODAL,
} from "components/modals";
import Table from "components/table";
import IconLink from "../../../../components/IconLink";
import { FaEdit, FaTrash } from "react-icons/fa";
import * as notifications from "../../../../notifications";
import { getViewMode, partial } from "../../../../utils";

const HierarchySplitsTable = ({ activeAccount, removeActionButtons = false, ...props }) => {
  if (!activeAccount) {
    // Still didn't fetch data
    return null;
  }

  const nSplits = props.activeHierarchySplits?.length;
  const isViewMode = getViewMode();
  const isDisabled = nSplits === 1 || isViewMode;

  const columns = [
    {
      Header: "Action",
      className: "action",
      Cell: ({ row }) => {
        if (row.original.model === "Hierarchy") {
          return (
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="Edit Split"
              onClick={() => {
                props.selectSplit(row.original);
                props.showModal(ACCOUNT_HIERARCHY_SPLIT_MODAL);
              }}
            />
          );
        }
        const id = row.original.id;
        const hierarchyId = props.activeHierarchy?.id;
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="Edit Split"
              onClick={() => {
                props.selectSplit(row.original);
                props.showModal(ACCOUNT_HIERARCHY_SPLIT_MODAL);
              }}
            />
            <IconLink
              Icon={FaTrash}
              iconConfig={{ className: "table--action-icon btn--red" }}
              tooltip="Delete Split"
              onClick={() => {
                props.showModal(DELETE_ACCOUNT_HIERARCHY_SPLIT_MODAL, { id, hierarchyId });
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Sales Representative",
      accessor: "sales_representative_name",
      className: "wrap-text",
    },
    {
      Header: "Sales Manager",
      accessor: "sales_manager_name",
      className: "wrap-text",
    },
    {
      Header: "National Account Manager",
      accessor: "national_account_manager_name",
      className: "wrap-text",
    },
    {
      Header: "National Account",
      accessor: "national_account_name",
      Cell: ({ row }) => {
        let national_account_name = row.original.national_account_name;
        let national_account_id = row.original.national_account_id;
        if (national_account_name) {
          return (
            <a
              style={{ color: "black" }}
              href={`/accounts/${national_account_id}/licensing/`}
              target="_blank"
              rel="noreferrer"
            >
              {national_account_name}
            </a>
          );
        } else {
          return "----";
        }
      },
    },
    {
      Header: "Split",
      accessor: "split_value",
      Cell: ({ row }) => {
        return (
          <div className="text-wrap">
            {`${row.original.split_value ? (row.original.split_value * 100).toFixed(3) : "0.000"}%`}{" "}
          </div>
        );
      },
    },
    {
      Header: "Lead",
      accessor: "lead",
      Cell: ({ row }) => {
        const handleCheckboxClick = async event => {
          const isChecked = event.target.checked;
          const hierarchyId = row.original.hierarchy || row.original.id;
          let isHierarchy = row.original.model === "Hierarchy";
          let method = partial(props.editAccountHierarchySplit, row.original.id);

          let values = {
            id: row.original.id,
            lead: isChecked,
            accountId: props.activeAccountId,
            hierarchy: hierarchyId,
            hierarchyId: hierarchyId,
            split_value: row.original.split_value,
          };
          try {
            if (isHierarchy) {
              // If the selected element to edit is the default split, override the method
              method = props.editAccountHierarchyAsASplit;
              await method(values);
            } else {
              await method(values);
            }
          } catch (error) {
            if (error.response.data.unique_active_hierarchy) {
              props.showModal(ACCOUNT_CHANGE_ACTIVE_HIERARCHY_MODAL, {
                hierarchyValues: { ...values, id: hierarchyId },
              });
            } else if (error.response.data.update_hierarchy_warning) {
              props.showModal(ACCOUNT_WARNING_UPDATE_HIERARCHY_MODAL, {
                values,
                method,
              });
            } else {
              console.log(error.response.data.update_hierarchy_warning);
              notifications.error("Error editing Hierarchy");
            }
          }
        };

        return (
          <div className="text-wrap">
            <input type="checkbox" checked={row.original.lead} disabled={isDisabled} onClick={handleCheckboxClick} />
          </div>
        );
      },
    },
  ];

  if (removeActionButtons) {
    columns.shift();
  }

  return (
    <Table
      columns={columns}
      data={props.activeHierarchySplits}
      defaultSort={true}
      paginated={false}
      emptyMessage="No Splits found"
      sortBy={[{ id: "lead", desc: true }]}
    />
  );
};

const mapStateToProps = state => {
  const activeAccount = activeAccountSelector(state);
  const choices = managementChoicesSelector(state);

  return {
    activeAccount,
    hierarchies: activeAccount ? activeAccount.hierarchies : [],
    activeAccountId: activeAccountIdSelector(state),
    activeHierarchy: activeHierarchySelector(state) || [],
    activeHierarchySplits: activeHierarchySelector(state)?.splits || [],
    salesRepChoices: choices.case_sales_representatives,
    nationalAccountChoices: choices.national_accounts,
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectAccountHierarchy,
  selectSplit,
  editAccountHierarchySplit,
  editAccountHierarchyAsASplit,
})(HierarchySplitsTable);
