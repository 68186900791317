import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { ILLUSTRATION_SCRAPPER_MODAL } from "components/modals";
import IllustrationScrapperForm from "./IllustrationScrapperForm";
import { fetchWinFlexChoices, fetchPolicy } from "../../../actions";

const IllustrationScrapperModal = props => {
  return (
    <FullPageModal modalType={ILLUSTRATION_SCRAPPER_MODAL} title={`Illustration Scrapper`}>
      <IllustrationScrapperForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  fetchWinFlexChoices,
  fetchPolicy,
})(IllustrationScrapperModal);
