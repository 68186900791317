import React from "react";
import { useDispatch } from "react-redux";

import * as modals from ".";
import * as actions from "actions";
import ConfirmationModal from "./ConfirmationModal";
import { fullNameDisplay } from "utils.js";

const DeleteModal = ({ modalType, onDelete, children }) => {
  const dispatch = useDispatch();
  return (
    <ConfirmationModal
      modalType={modalType}
      onConfirm={() => {
        onDelete();
        dispatch(actions.hideModal(modalType));
      }}
    >
      {children}
    </ConfirmationModal>
  );
};

export const ConfirmFileDeleteModal = props => {
  const dispatch = useDispatch();
  return (
    <ConfirmationModal
      modalType={modals.CONFIRM_FILE_DELETE_MODAL}
      onConfirm={() => {
        props.onConfirm();
        dispatch(actions.hideModal(modals.CONFIRM_FILE_DELETE_MODAL));
      }}
    >
      Are you sure you want to delete this file?
    </ConfirmationModal>
  );
};

const DeleteFileModal = props => <DeleteModal {...props}>Are you sure you want to delete this file?</DeleteModal>;

// Case
export const DeleteCaseModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_CASE_MODAL} onDelete={() => dispatch(actions.deleteCase(id))}>
      Are you sure you want to delete this case? All saved data will be lost.
    </DeleteModal>
  );
};

export const DeleteCaseFileModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_CASE_FILE_MODAL} onDelete={() => dispatch(actions.deleteCaseFile(id))}>
      Are you sure you want to delete this case file? All saved data will be lost.
    </DeleteModal>
  );
};

// Insured
export const DeleteInsuredModal = ({ id, first_name, last_name }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_INSURED_MODAL} onDelete={() => dispatch(actions.deleteInsuredInfo(id))}>
      Are you sure you want to delete insured "{fullNameDisplay(last_name, first_name)}
      "? Any saved data will be lost.
    </DeleteModal>
  );
};

export const DeleteLifeExpectancyModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_LIFE_EXPECTANCY_MODAL}
      onDelete={() => dispatch(actions.deleteLifeExpectancy(id))}
    >
      Are you sure you want to delete this life expectancy? All saved data will be lost.
    </DeleteModal>
  );
};

// Owners
export const DeleteOwnerEntityModal = ({ id, entity_name }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_OWNER_ENTITY_MODAL} onDelete={() => dispatch(actions.deleteOwnerEntity(id))}>
      Are you sure you want to delete Owner Entity "{entity_name}"?
      <br />
      Any saved data will be lost.
    </DeleteModal>
  );
};

export const DeleteOwnerSignerModal = ({ id, full_legal_name }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_OWNER_SIGNER_MODAL} onDelete={() => dispatch(actions.deleteOwnerSigner(id))}>
      Are you sure you want to delete Owner Signer "{full_legal_name}"?
      <br />
      Any saved data will be lost.
    </DeleteModal>
  );
};

export const DeleteOwnerPersonModal = ({ id, first_name, last_name }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_OWNER_PERSON_MODAL} onDelete={() => dispatch(actions.deleteOwnerPerson(id))}>
      Are you sure you want to delete Owner Person "{fullNameDisplay(last_name, first_name)}"?
      <br />
      Any saved data will be lost.
    </DeleteModal>
  );
};

// Insured
export const DeleteOwnerInsuredModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_OWNER_INSURED_MODAL}
      onDelete={() => dispatch(actions.deleteOwnerInsured(id))}
    >
      Are you sure you want to delete this Owner?
      <br />
      Insured will remain unchanged.
    </DeleteModal>
  );
};

// Beneficiaries
export const DeleteBeneficiaryModal = ({ beneficiaryType, id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_BENEFICIARY_MODAL}
      onDelete={() => dispatch(actions.deleteBeneficiary(beneficiaryType, id))}
    >
      Are you sure you want to delete this Beneficiary?
    </DeleteModal>
  );
};

export const DeleteBeneficiarySignerModal = ({ id, entity_id, full_legal_name }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_BENEFICIARY_SIGNER_MODAL}
      onDelete={() => dispatch(actions.deleteBeneficiarySigner(id, entity_id))}
    >
      Are you sure you want to delete Beneficiary Signer "{full_legal_name}"?
    </DeleteModal>
  );
};

// Auction
export const DeleteAuctionRoundModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_AUCTION_ROUND_MODAL}
      onDelete={() => dispatch(actions.deleteAuctionRound(id))}
    >
      Are you sure you want to delete this auction round? All saved data will be lost.
    </DeleteModal>
  );
};

export const DeleteMarketAuctionActivityModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_MARKET_AUCTION_ACTIVITY_MODAL}
      onDelete={() => dispatch(actions.deleteMarketAuctionActivity(id))}
    >
      Are you sure you want to delete this Market auction activity? All saved data will be lost.
    </DeleteModal>
  );
};

export const DeleteFunderAuctionActivityModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_FUNDER_AUCTION_ACTIVITY_MODAL}
      onDelete={() => dispatch(actions.deleteFunderAuctionActivity(id))}
    >
      Are you sure you want to delete this Funder auction activity? All saved data will be lost.
    </DeleteModal>
  );
};

export const DeleteAuctionActivityFileModal = ({ auctionActivityId }) => {
  const dispatch = useDispatch();
  return (
    <DeleteFileModal
      modalType={modals.DELETE_AUCTION_ACTIVITY_FILE_MODAL}
      onDelete={() => dispatch(actions.deleteAuctionActivityFile(auctionActivityId))}
    />
  );
};

export const DeleteProductTemplateModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_PRODUCT_TEMPLATE_MODAL}
      onDelete={() => dispatch(actions.deleteProductTemplate(id))}
    >
      Are you sure you want to delete this Product Template?
    </DeleteModal>
  );
};

export const DeleteCarrierModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_CARRIER_MODAL} onDelete={() => dispatch(actions.deleteCarrier(id))}>
      Are you sure you want to delete this Carrier?
    </DeleteModal>
  );
};

export const DeleteCarrierFileModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_CARRIER_FILE_MODAL} onDelete={() => dispatch(actions.deleteCarrierFile(id))}>
      Are you sure you want to delete this Carrier FIle?
    </DeleteModal>
  );
};

export const DeleteFunderModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_FUNDER_MODAL} onDelete={() => dispatch(actions.deleteFunder(id))}>
      Are you sure you want to delete this Funder?
    </DeleteModal>
  );
};

export const DeleteFunderContactModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_FUNDER_CONTACT_MODAL}
      onDelete={() => dispatch(actions.deleteFunderContact(id))}
    >
      Are you sure you want to delete this Funder Contact?
    </DeleteModal>
  );
};

export const DeleteFunderEntityModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_FUNDER_ENTITY_MODAL}
      onDelete={() => dispatch(actions.deleteFunderEntity(id))}
    >
      Are you sure you want to delete this Funder Entity?
    </DeleteModal>
  );
};

export const DeleteSecurityIntermediaryModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_SECURITY_INTERMEDIARY_MODAL}
      onDelete={() => dispatch(actions.deleteSecurityIntermediary(id))}
    >
      Are you sure you want to delete this Security Intermediary?
    </DeleteModal>
  );
};

export const DeleteTradingDocumentModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_TRADING_DOCUMENT_MODAL}
      onDelete={() => dispatch(actions.deleteTradingDocument(id))}
    >
      Are you sure you want to delete this Trading Document?
    </DeleteModal>
  );
};

export const DeleteProductModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_PRODUCT_MODAL} onDelete={() => dispatch(actions.deleteProduct(id))}>
      Are you sure you want to delete this Product?
    </DeleteModal>
  );
};

export const DeleteAccountModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_ACCOUNT_MODAL} onDelete={() => dispatch(actions.deleteAccount(id))}>
      Are you sure you want to delete this Account?
    </DeleteModal>
  );
};

export const DeleteAccountTaskModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_ACCOUNT_TASK_MODAL} onDelete={() => dispatch(actions.deleteAccountTask(id))}>
      Are you sure you want to delete this Task?
    </DeleteModal>
  );
};

export const DeleteLeadTaskModal = ({ taskId, leadId }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_LEAD_TASK_MODAL}
      onDelete={() => dispatch(actions.deleteLeadTask(taskId, leadId))}
    >
      Are you sure you want to delete this Task?
    </DeleteModal>
  );
};

export const DeleteCaseTaskModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_CASE_TASK_MODAL} onDelete={() => dispatch(actions.deleteCaseTask(id))}>
      Are you sure you want to delete this Task?
    </DeleteModal>
  );
};

export const DeleteClinicalResearchModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_CLINICAL_RESEARCH_MODAL}
      onDelete={() => dispatch(actions.deleteClinicalResearch(id))}
    >
      Are you sure you want to delete this Clinical Research?
    </DeleteModal>
  );
};

export const DeleteEmailTemplateModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_EMAIL_TEMPLATE_MODAL}
      onDelete={() => dispatch(actions.deleteEmailTemplate(id))}
    >
      Are you sure you want to delete this Email Template?
    </DeleteModal>
  );
};

export const DeleteConditionListModal = ({ id, assessmentId }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_CONDITION_LIST_MODAL}
      onDelete={() => dispatch(actions.deleteMortalityAssessmentCondition(id, assessmentId))}
    >
      Are you sure you want to delete this Condition from the Condition List?
    </DeleteModal>
  );
};

export const DeleteAccountCarrierAppointmentModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_ACCOUNT_CARRIER_APPOINTMENT_MODAL}
      onDelete={() => dispatch(actions.deleteAccountCarrierAppointment(id))}
    >
      Are you sure you want to delete this Carrier Appointment?
    </DeleteModal>
  );
};

export const DeleteAccountBackgroundDocumentationModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_ACCOUNT_BACKGROUND_DOCUMENTATION_MODAL}
      onDelete={() => dispatch(actions.deleteAccountBackgroundDocumentation(id))}
    >
      Are you sure you want to delete this Background Documentation?
    </DeleteModal>
  );
};

export const DeleteAccountEOBondingModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_ACCOUNT_EO_BONDING_MODAL}
      onDelete={() => dispatch(actions.deleteAccountEOBonding(id))}
    >
      Are you sure you want to delete this EO Bonding?
    </DeleteModal>
  );
};

export const DeleteAccountLicenseModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_ACCOUNT_LICENSE_MODAL}
      onDelete={() => dispatch(actions.deleteAccountLicense(id))}
    >
      Are you sure you want to delete this License?
    </DeleteModal>
  );
};

export const DeleteAccountAgreementModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_ACCOUNT_AGREEMENT_MODAL}
      onDelete={() => dispatch(actions.deleteAccountAgreement(id))}
    >
      Are you sure you want to delete this Sourcing Agreement?
    </DeleteModal>
  );
};

export const DeleteAccountHierarchyModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_ACCOUNT_HIERARCHY_MODAL}
      onDelete={() => dispatch(actions.deleteAccountHierarchy(id))}
    >
      Are you sure you want to delete this Sale Hierarchy?
    </DeleteModal>
  );
};

export const DeleteAccountHierarchySplitModal = ({ id, hierarchyId }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_ACCOUNT_HIERARCHY_SPLIT_MODAL}
      onDelete={() => dispatch(actions.deleteAccountHierarchySplit(id, hierarchyId))}
    >
      Are you sure you want to delete this Split?
    </DeleteModal>
  );
};

export const DeleteAccountAffiliationModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_ACCOUNT_AFFILIATION_MODAL}
      onDelete={() => dispatch(actions.deleteAccountAffiliation(id))}
    >
      Are you sure you want to delete this Affiliation?
    </DeleteModal>
  );
};

export const DeleteLeadModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_LEAD_MODAL} onDelete={() => dispatch(actions.deleteLead(id))}>
      Are you sure you want to delete this Lead?
    </DeleteModal>
  );
};

export const DeleteParameterModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_PARAMETER_MODAL} onDelete={() => dispatch(actions.deleteParameter(id))}>
      Are you sure you want to delete this Parameter?
    </DeleteModal>
  );
};

export const RemoveRelatedParameterModal = ({ id, relatedParameterId }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.REMOVE_RELATED_PARAMETER_MODAL}
      onDelete={() => dispatch(actions.removeRelatedParameter(id, relatedParameterId))}
    >
      Are you sure you want to remove this Related Parameter?
    </DeleteModal>
  );
};

export const DeleteSynonymModal = ({ id, parameterSynonym = false }) => {
  const dispatch = useDispatch();
  if (parameterSynonym) {
    return (
      <DeleteModal
        modalType={modals.DELETE_SYNONYM_MODAL}
        onDelete={() => dispatch(actions.deleteParameterSynonym(id))}
      >
        Are you sure you want to delete this Synonym?
      </DeleteModal>
    );
  } else {
    return (
      <DeleteModal modalType={modals.DELETE_SYNONYM_MODAL} onDelete={() => dispatch(actions.deleteSynonym(id))}>
        Are you sure you want to delete this Synonym?
      </DeleteModal>
    );
  }
};

export const DeleteMortalityAssessmentModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_MORTALITY_ASSESSMENT_MODAL}
      onDelete={() => dispatch(actions.deleteMortalityAssessment(id))}
    >
      Are you sure you want to delete this Mortality Assessment?
    </DeleteModal>
  );
};

export const DeleteStateModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal modalType={modals.DELETE_STATE_MODAL} onDelete={() => dispatch(actions.deleteState(id))}>
      Are you sure you want to delete this State?
    </DeleteModal>
  );
};

export const DeleteStateBrokerFileModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_STATE_BROKER_FILE_MODAL}
      onDelete={() => dispatch(actions.deleteStateFile(id, "BROKER"))}
    >
      Are you sure you want to delete this State FIle?
    </DeleteModal>
  );
};

export const DeleteStateProviderFileModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_STATE_PROVIDER_FILE_MODAL}
      onDelete={() => dispatch(actions.deleteStateFile(id, "PROVIDER"))}
    >
      Are you sure you want to delete this State FIle?
    </DeleteModal>
  );
};

export const DeleteCashFlowModal = ({ id, caseId }) => {
  const dispatch = useDispatch();
  return (
    <DeleteModal
      modalType={modals.DELETE_CASE_TASK_MODAL}
      onDelete={() => dispatch(actions.deleteCashFlow(id, caseId))}
    >
      Are you sure you want to delete this Cash Flow?
    </DeleteModal>
  );
};
