import { makeChoices } from "../../../utils";

export const cashFlowTypesChoices = [
  { value: "Agency Commission", label: "Agency Commission", direction: 1 },
  { value: "Agent Appointment Referal Fee", label: "Agent Appointment Referal Fee", direction: -1 },
  { value: "Bank Fee", label: "Bank Fee", direction: -1 },
  { value: "Carrier Commission", label: "Carrier Commission", direction: 1 },
  { value: "Carrier POS Fee", label: "Carrier POS Fee", direction: -1 },
  { value: "Claim Payment", label: "Claim Payment", direction: 1 },
  { value: "Escrow Fee", label: "Escrow Fee", direction: -1 },
  { value: "Interest Charge", label: "Interest Charge", direction: -1 },
  { value: "LE Fee", label: "LE Fee", direction: -1 },
  { value: "Legal Fee", label: "Legal Fee", direction: -1 },
  { value: "MR Fee", label: "MR Fee", direction: -1 },
  { value: "Policy Loan", label: "Policy Loan", direction: 1 },
  { value: "Policy Withdrawal", label: "Policy Withdrawal", direction: 1 },
  { value: "Premium Advance", label: "Premium Advance", direction: -1 },
  { value: "Premium Payment", label: "Premium Payment", direction: -1 },
  { value: "Premium Reimbursement", label: "Premium Reimbursement", direction: -1 },
  { value: "Purchase Price", label: "Purchase Price", direction: -1 },
  { value: "Purchase Price Advance", label: "Purchase Price Advance", direction: -1 },
  { value: "Sell Price", label: "Sell Price", direction: 1 },
  { value: "Servicing Fee", label: "Servicing Fee", direction: -1 },
  { value: "SI Fee", label: "SI Fee", direction: -1 },
];

const cashFlowStatusList = ["Received", "Paid", "Expected", "Placed In Escrow", "Assumed"];

const cashFlowEntitiesList = [
  "LRC Family Partnership, LLC",
  "LCFP V1, LLC",
  "LifeRoc, LLC",
  "LifeRoc Capital, LLC",
  "LifeRoc FInancial & Insurance Services, LLC",
];

export const cashFlowDirectionChoices = [
  { value: 1, label: "Inflow" },
  { value: -1, label: "Outflow" },
];

export const cashFlowDisplayTypesChoices = [...cashFlowTypesChoices, { value: "Total", label: "Total" }];
export const cashFlowStatusChoices = makeChoices(cashFlowStatusList);
export const cashFlowEntitiesChoices = makeChoices(cashFlowEntitiesList);
