import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "../../../../notifications";
import { EditorField, FormCollapsible, DateInput, Select } from "components/forms";
import { createAccountBackgroundDocumentation, editAccountBackgroundDocumentation } from "actions";
import { activeAccountIdSelector, activeBackgroundDocumentationSelector, managementChoicesSelector } from "reducers";
import { partial } from "utils";
import WideSaveButton from "components/buttons/WideSaveButton";
import { backgroundDocumentationTypeChoices } from "constants.js";

const BackgroundDocumentationForm = ({ activeBackgroundDocumentation, ...props }) => {
  let { document_type, document_date, notes } = activeBackgroundDocumentation;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        document_type,
        document_date,
        notes,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isEmpty(activeBackgroundDocumentation)
          ? props.createAccountBackgroundDocumentation
          : partial(props.editAccountBackgroundDocumentation, activeBackgroundDocumentation.id);

        values.account = props.activeAccountId;

        try {
          await method(values);
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Background Documentation");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Background Documentation Detail">
            <Form.Row>
              <Select label="Document type" name="document_type" options={backgroundDocumentationTypeChoices} />
              <DateInput label="Document Date" name="document_date" />
            </Form.Row>
            <Form.Row>
              <EditorField label="Notes" name="notes" />
            </Form.Row>
            <WideSaveButton submitRef={props.submitRef} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);

  return {
    activeAccountId: activeAccountIdSelector(state),
    activeBackgroundDocumentation: activeBackgroundDocumentationSelector(state) || {},
    carrierChoices: choices.carriers,
  };
};

export default connect(mapStateToProps, {
  createAccountBackgroundDocumentation,
  editAccountBackgroundDocumentation,
})(BackgroundDocumentationForm);
