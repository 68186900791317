import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import {
  TextInput,
  FormFilter,
  SubmitSearchButton,
  ClearFiltersButton,
  Select,
  StateSelect,
  DateInput,
  BooleanSelect,
} from "components/forms";
import { fetchAccounts, fetchLifeSettlementChoices, updateAccountFilters } from "actions";
import { managementChoicesSelector, storedAccountsFiltersSelector } from "reducers";
import {
  ACCOUNT_STATUS,
  ACCOUNT_TYPES,
  AFFILIATION_PRACTICE_TYPES,
  MARKETING_SOURCES,
  PRACTICE_TYPES,
} from "./constants";
import { yesNoNotSelectedChoices, affiliationStatusChoices } from "../../constants";
import { makeChoices, prepareMultipleSelect } from "../../utils";

const AccountsFilterForm = ({ fetchAccounts, fetchLifeSettlementChoices, currentFilters, ...props }) => {
  React.useEffect(() => {
    fetchLifeSettlementChoices();
  }, []);

  // Practice Type Multi Select //
  const practiceTypeRef = useRef();
  let [practiceType, setPracticeType] = React.useState("");
  const handlePracticeTypeChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedStatusValues = selectedOptions.map(o => o.value).join(",");
      setPracticeType(commaSeparatedStatusValues);
    } else {
      setPracticeType(undefined);
    }
  };
  let practice_type_initial = prepareMultipleSelect("practice_type", PRACTICE_TYPES, currentFilters);

  // Affiliation Type Multi Select //
  const affiliationTypeRef = useRef();
  let [affiliationType, setAffiliationType] = React.useState("");
  const handleAffiliationChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedStatusValues = selectedOptions.map(o => o.value).join(",");
      setAffiliationType(commaSeparatedStatusValues);
    } else {
      setAffiliationType(undefined);
    }
  };
  let affiliation_practice_type_initial = prepareMultipleSelect(
    "affiliation_practice_type",
    AFFILIATION_PRACTICE_TYPES,
    currentFilters,
  );

  let hrStyle = {
    borderTop: "2px solid rgba(0, 0, 0, .1)",
  };

  let initialValues = {
    last_name: currentFilters?.last_name || "",
    first_name: currentFilters?.first_name || "",
    entity_name: currentFilters?.entity_name || "",
    type: currentFilters?.type,
    phone: currentFilters?.phone || "",
    has_cell_phone: currentFilters?.has_cell_phone || "",
    email: currentFilters?.email || "",
    has_work_email: currentFilters?.has_work_email || "",
    city: currentFilters?.city || "",
    state: currentFilters?.state || "",
    practice_type: currentFilters?.practice_type || "",
    life_insurance: currentFilters?.life_insurance || "",
    life_settlements: currentFilters?.life_settlements || "",
    medicare: currentFilters?.medicare || "",
    notes: currentFilters?.notes || "",
    submit_start_date: currentFilters?.submit_start_date || "",
    submit_end_date: currentFilters?.submit_end_date || "",
    ever_submitted: currentFilters?.ever_submitted || "",
    sales_rep: currentFilters?.sales_rep || "",
    sales_manager: currentFilters?.sales_manager || "",
    national_account_manager: currentFilters?.national_account_manager || "",
    national_account: currentFilters?.national_account || "",
    affiliation_practice_type: currentFilters?.affiliation_practice_type || "",
    affiliation_entity_name: currentFilters?.affiliation_entity_name || "",
    affiliation_status: currentFilters?.affiliation_status || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const filters = {
          ...values,
          practice_type: practiceType,
          affiliation_practice_type: affiliationType,
        };
        props.updateAccountFilters(filters);
        await fetchAccounts(filters);
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm }) => {
        const resetCallback = () => {
          props.updateAccountFilters({});
          practiceTypeRef.current.select?.clearValue();
          affiliationTypeRef.current.select?.clearValue();
          resetForm();
          fetchAccounts();
        };

        return (
          <FormFilter title={"Accounts Search"} onSubmit={handleSubmit}>
            <Form.Row>
              <TextInput label="Last Name" name="last_name" />
              <TextInput label="First Name" name="first_name" />
              <TextInput label="Entity Name" name="entity_name" />
              <Select label="Account Type" name="type" placeholder="Select Account Type" options={ACCOUNT_TYPES} />
            </Form.Row>
            <Form.Row>
              <TextInput label="Phone" name="phone" md={3} />
              <BooleanSelect label="Has Cell Phone" name="has_cell_phone" md={3} />
              <TextInput label="Emails" name="email" md={3} />
              <BooleanSelect label="Has Work Email" name="has_work_email" md={3} />
            </Form.Row>
            <Form.Row>
              <TextInput label="City" name="city" md={3} />
              <StateSelect label="State" name="state" placeholder="Select State" md={3} />
              <Select
                label="Account Status"
                name="status"
                placeholder="Select Account Status"
                options={ACCOUNT_STATUS}
                md={3}
              />
              <Select
                label="Marketing Source"
                name="marketing_source"
                placeholder="Select Marketing Source"
                options={MARKETING_SOURCES}
                md={3}
              />
            </Form.Row>
            <Form.Row>
              <Select
                label="Practice Type"
                name="practice_type"
                placeholder="Select Practice Type"
                options={PRACTICE_TYPES}
                isMulti={true}
                submitRef={practiceTypeRef}
                onChange={handlePracticeTypeChange}
                defaultValue={practice_type_initial}
              />
              <Select label="Life" name="life_insurance" placeholder="Select Life" options={yesNoNotSelectedChoices} />
              <Select
                label="Life Settlements"
                name="life_settlements"
                placeholder="Select Life Settlements"
                options={yesNoNotSelectedChoices}
              />
              <Select
                label="Medicare"
                name="medicare"
                placeholder="Select Medicare"
                options={yesNoNotSelectedChoices}
              />
            </Form.Row>
            <Form.Row>
              <DateInput label="Submit Start Date" name="submit_start_date" md={3} />
              <DateInput label="Submit End Date" name="submit_end_date" md={3} />
              <BooleanSelect label="Ever Submitted" name="ever_submitted" md={3} />
            </Form.Row>
            <Form.Row>
              <Select
                label="Sales Rep"
                name="sales_rep"
                placeholder="Select Sales Rep"
                options={props.salesRepChoices.getChoices({ sortByLabel: true })}
              />
              <Select
                label="Sales Manager"
                name="sales_manager"
                placeholder="Select Sales Manager"
                options={props.salesRepChoices.getChoices({ sortByLabel: true })}
              />
              <Select
                label="Nat Acc Manager"
                name="national_account_manager"
                placeholder="Select National Acc Manager"
                options={props.salesRepChoices.getChoices({ sortByLabel: true })}
              />
              <Select
                label="National Account"
                name="national_account"
                placeholder="Select National Account"
                options={props.nationalAccountChoices.getChoices({ sortByLabel: true })}
              />
            </Form.Row>
            <Form.Row>
              <TextInput
                label="Notes Search"
                name="notes_search"
                tooltip='Use "word1 word2" for exact search, * at the beginning as wildcard'
                md={3}
              />
              <Select
                label="Notes Search Type"
                name="notes_search_type"
                options={[
                  { value: "OR", label: "OR" },
                  { value: "AND", label: "AND" },
                ]}
                md={3}
              />
            </Form.Row>
            <hr style={hrStyle} />
            <Form.Row>
              <Select
                label="Affiliation Practice Type"
                name="affiliation_practice_type"
                placeholder="Select Affiliation Type"
                options={AFFILIATION_PRACTICE_TYPES}
                isMulti={true}
                submitRef={affiliationTypeRef}
                onChange={handleAffiliationChange}
                defaultValue={affiliation_practice_type_initial}
                md={3}
              />
              <TextInput label="Affiliation Entity Name" name="affiliation_entity_name" md={3} />
              <Select
                label="Affiliation Status"
                name="affiliation_status"
                placeholder="Select Affiliation Status"
                options={affiliationStatusChoices}
              />
            </Form.Row>
            <hr style={hrStyle} />
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetCallback} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const currentFilters = storedAccountsFiltersSelector(state);
  let sourcingAgreementChoices = makeChoices(["None", "Active", "Inactive", "Pending"]);

  return {
    salesRepChoices: choices.case_sales_representatives,
    nationalAccountChoices: choices.national_accounts,
    sourcingAgreementChoices,
    currentFilters,
  };
};

export default connect(mapStateToProps, {
  fetchAccounts,
  updateAccountFilters,
  fetchLifeSettlementChoices,
})(AccountsFilterForm);
