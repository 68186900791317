import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { CASH_FLOW_MODAL } from "components/modals";
import CashFlowForm from "./CashFlowForm";
import { activeCashFlowSelector, funderWinningBidSelector, marketWinningBidSelector } from "../../../reducers";
import MarketActivityForm from "../auction/activities/MarketActivityForm";
import FunderActivityForm from "../auction/activities/FunderActivityForm";

const CashFlowModal = ({ activeCashFlow, funderWinningBid, marketWinningBid }) => {
  return (
    <FullPageModal modalType={CASH_FLOW_MODAL}>
      <CashFlowForm record={activeCashFlow} />
      {marketWinningBid &&
        (activeCashFlow?.type === "Purchase Price" || activeCashFlow?.type === "Premium Reimbursement") && (
          <MarketActivityForm />
        )}
      {funderWinningBid && (activeCashFlow?.type === "Sell Price" || activeCashFlow?.type === "Premium Advance") && (
        <FunderActivityForm />
      )}
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    activeCashFlow: activeCashFlowSelector(state),
    funderWinningBid: funderWinningBidSelector(state),
    marketWinningBid: marketWinningBidSelector(state),
  };
};

export default connect(mapStateToProps, {})(CashFlowModal);
