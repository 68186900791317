import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { ACCOUNT_EO_BONDING_MODAL } from "components/modals";
import EOBondingForm from "./EOBondingForm";
import { activeEOBondingSelector } from "reducers";
import SingleFileDropZone from "../../../../components/SingleFileDropZone";
import WideSaveButton from "components/buttons/WideSaveButton";
import { isAdmin } from "../../../../permissions";
import { uploadAccountFile } from "../../../../actions";

const EOBondingModal = ({ activeEOBonding, hasAdminPermission, ...props }) => {
  let isSaved = activeEOBonding && activeEOBonding.id;
  const submitRef = React.useRef();
  let title = isSaved ? "Edit EO & Bonding" : "New EO & Bonding";

  return (
    <FullPageModal modalType={ACCOUNT_EO_BONDING_MODAL} title={title} style={{ content: { overflow: "visible" } }}>
      <EOBondingForm submitRef={submitRef} />
      {isSaved && hasAdminPermission ? (
        <div>
          <SingleFileDropZone
            currentFile={activeEOBonding.name ? activeEOBonding : null}
            uploadFile={file => {
              props.uploadAccountFile(file, "EO_BONDING");
            }}
            fileDownloadUrl={`/api/account/`}
          />
        </div>
      ) : null}
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    activeEOBonding: activeEOBondingSelector(state) || {},
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, { uploadAccountFile })(EOBondingModal);
