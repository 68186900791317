import React from "react";
import { connect } from "react-redux";
import * as moment from "moment";
import { DATE_FORMAT, requiredOfChoices, statusChoicesDocumentStatus, vendorChoices } from "../../../constants";
import { STATUS } from "./constants.js";
import {
  loadingCaseFilesSelector,
  caseFilesSelector,
  loadingSelector,
  insuredListSelector,
  ownerListSelector,
  userChoicesSelector,
} from "reducers";
import {
  showModal,
  downloadFile,
  selectCaseFile,
  editCaseFile,
  editMultipleCaseFiles,
  deleteMultipleCaseFiles,
  createCaseFile,
  uploadCaseFile,
  fetchCaseFilesByCaseDocumentId,
} from "actions";
import DataGrid, { Row, textEditor, SelectColumn } from "react-data-grid";
import { Menu, Item, Submenu, useContextMenu } from "react-contexify";
import "react-contexify/ReactContexify.css";
import {
  ActionFormatter,
  CheckboxFormatter,
  DateEditor,
  DateFormatter,
  DateOverdueFormatter,
  DropdownEditor,
  DropdownFormatter,
  DropdownStatusFormatter,
  NotesFormatter,
} from "components/table";
import Loader from "components/Loader";
import { getInsuredLabel, getOwnerLabel } from "./CategorySelect";
import { CONFIRM_FILE_DELETE_MODAL, CONFIRM_FOLLOW_UP_REPLACE_MODAL } from "components/modals";
import * as notifications from "notifications";
import { getLabel, getViewMode } from "../../../utils";
import { isAdmin, isReadMode, isSalesRep } from "permissions";

function getComparator(sortColumn) {
  function compare(a, b) {
    if (!a[sortColumn]) {
      if (!b[sortColumn]) {
        return 0;
      } else {
        return 1;
      }
    } else if (!b[sortColumn]) {
      return -1;
    } else {
      return a[sortColumn].localeCompare(b[sortColumn]);
    }
  }

  switch (sortColumn) {
    case "category":
    case "sub_category":
    case "description":
    case "required_of":
    case "follow_up_date":
    case "date_requested":
    case "responsible_name":
    case "notes":
      return (a, b) => {
        return compare(a, b);
      };
    case "status":
      return (a, b) => {
        return a[sortColumn] - b[sortColumn];
      };
    case "file_redacted":
    case "funder_initial_review":
    case "funder_dd_review":
      return (a, b) => {
        return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
      };
    case "document_date":
    case "date_received":
      return (a, b) => {
        a.document_date = a.document_date || "";
        b.document_date = b.document_date || "";
        return compare(a, b);
      };
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
}

const CaseFilesTable = ({ caseFiles, ...props }) => {
  const [rows, setRows] = React.useState([]);
  const [sortColumns, setSortColumns] = React.useState([{ columnKey: "status", direction: "ASC" }]);
  const [selectedRows, setSelectedRows] = React.useState(new Set());
  const [rowIdx, setRowIdx] = React.useState(null);

  const NUMBER_OF_COLUMNS = 14;

  async function add7FollowUp() {
    let targetIds;
    if (selectedRows.size === 0) {
      targetIds = rowIdx;
    } else {
      targetIds = Array.from(selectedRows);
    }
    const target_day = moment().add("days", 7).format(DATE_FORMAT.SERVER.MOMENT);
    props.editMultipleCaseFiles({
      action: "change_follow_up_date",
      new_follow_up_date: target_day,
      ids: targetIds,
    });
  }

  async function chooseFollowUp(date) {
    let targetIds;
    if (selectedRows.size === 0) {
      targetIds = rowIdx;
    } else {
      targetIds = Array.from(selectedRows);
    }
    const target_day = moment(date).format(DATE_FORMAT.SERVER.MOMENT);
    props.editMultipleCaseFiles({
      action: "change_follow_up_date",
      new_follow_up_date: target_day,
      ids: targetIds,
    });
  }

  async function changeStatus({ data }) {
    let targetIds;
    if (selectedRows.size === 0) {
      targetIds = rowIdx;
    } else {
      targetIds = Array.from(selectedRows);
    }
    props.editMultipleCaseFiles({
      action: "change_status",
      new_status: data.status,
      ids: targetIds,
    });
  }

  async function deleteRow(e) {
    let targetIds = [];
    if (selectedRows.size === 0) {
      targetIds = rowIdx;
    } else {
      targetIds = Array.from(selectedRows);
    }
    props.editMultipleCaseFiles({
      action: "delete",
      ids: targetIds,
    });
  }

  // Disable browser's default drag behavior
  window.addEventListener("dragover", e => {
    e.preventDefault();
  });

  const updateCaseFileRows = () => {
    let _rows = [];
    for (const c of caseFiles) {
      c.type = "MASTER";
      _rows.push(c);
    }
    setRows(_rows);
  };

  let columns = [];
  if (!props.readMode) {
    columns.push(SelectColumn);
  }
  columns.push(
    {
      name: "Action",
      key: "id",
      width: 180,
      colSpan(args) {
        return args.type === "ROW" && args.row.type === "DETAIL" ? NUMBER_OF_COLUMNS : undefined;
      },
      renderCell({ row }) {
        return ActionFormatter({
          selectCaseFile: props.selectCaseFile,
          showModal: props.showModal,
          downloadFile: props.downloadFile,
          caseFiles: caseFiles,
          isViewMode: props.isViewMode,
          readMode: props.readMode,
          fetchCaseFilesByCaseDocumentId: props.fetchCaseFilesByCaseDocumentId,
          row: row,
          isSalesRep: props.isSalesRep,
        });
      },
      sortable: false,
      renderHeaderCell: () => (
        <div>
          <div style={{ marginTop: 23 }}>{"Action"}</div>
        </div>
      ),
    },
    {
      name: "Category",
      key: "category",
      width: 180,
      renderCell({ row }) {
        let category = row.category;
        if (!category) {
          return category;
        }

        if (category.indexOf("Owner") !== -1) {
          category = getOwnerLabel(category, props.ownerList, props.insuredList);
        } else if (category.indexOf("Insured") !== -1) {
          category = getInsuredLabel(category, props.insuredList);
        }

        return category;
      },
    },
    {
      name: "Sub Category",
      key: "sub_category",
      width: 120,
    },
    {
      name: "Description",
      key: "description",
      width: 200,
      renderEditCell: !props.readMode ? textEditor : null,
      renderCell({ row }) {
        const value = row.description;
        if (row.sub_category === "LE" && value) {
          return getLabel(vendorChoices, value) || value;
        } else {
          return value;
        }
      },
    },
    {
      name: "Owner",
      key: "responsible_name",
      width: 110,
    },
    {
      name: "Responsible",
      key: "required_of",
      width: 125,
      renderCell({ row }) {
        return DropdownFormatter({
          fieldName: "required_of",
          choices: requiredOfChoices,
          row: row,
        });
      },
      renderEditCell: !props.readMode
        ? DropdownEditor({
            fieldName: "required_of",
            choices: requiredOfChoices,
            editCallback: props.editCaseFile,
          })
        : null,
    },
    {
      name: "Date Requested",
      key: "date_requested",
      width: 100,
      sortable: true,
      renderCell({ row }) {
        return DateFormatter({
          fieldName: "date_requested",
          row: row,
        });
      },
      renderEditCell: !props.readMode ? DateEditor : null,
    },
    {
      name: "Date Received",
      key: "date_received",
      width: 100,
      renderCell({ row }) {
        return DateFormatter({
          fieldName: "date_received",
          row: row,
        });
      },
      renderEditCell: !props.readMode ? DateEditor : null,
    },
    {
      name: "Status",
      key: "status",
      width: 120,
      renderCell({ row }) {
        return DropdownStatusFormatter({
          fieldName: "status",
          choices: statusChoicesDocumentStatus,
          row: row,
        });
      },
      renderEditCell: !props.readMode
        ? DropdownEditor({
            fieldName: "status",
            choices: statusChoicesDocumentStatus,
            editCallback: props.editCaseFile,
          })
        : null,
    },
    {
      name: "Notes",
      key: "notes",
      width: 260,
      renderCell({ row }) {
        return NotesFormatter({
          selectCaseFile: props.selectCaseFile,
          showModal: props.showModal,
          fieldName: "notes",
          row: row,
          readMode: props.readMode,
        });
      },
      renderEditCell: !props.readMode ? textEditor : null,
    },
    {
      name: "Document Date",
      key: "document_date",
      height: 100,
      renderCell({ row }) {
        return DateFormatter({
          fieldName: "document_date",
          row: row,
        });
      },
      renderEditCell: !props.readMode ? DateEditor : null,
    },
    {
      name: "Follow Up Date",
      key: "follow_up_date",
      width: 100,
      renderCell({ row }) {
        return DateOverdueFormatter({
          fieldName: "follow_up_date",
          row: row,
        });
      },
      renderEditCell: !props.readMode ? DateEditor : null,
    },
    {
      name: "File Redact",
      key: "file_redacted",
      width: 60,
      renderCell({ row }) {
        return CheckboxFormatter({
          fieldName: "file_redacted",
          row: row,
        });
      },
    },
  );

  function EmptyRowsRenderer() {
    return (
      <div style={{ textAlign: "center", marginLeft: 20 }}>
        <Loader isLoading={props.loading.caseFiles} text={"Loading Files & Requirements"} />
      </div>
    );
  }

  React.useEffect(() => {
    if (!props.loading.caseFiles) {
      updateCaseFileRows();
    }
  }, [props.loading.caseFiles]);

  let style = {
    // ill do fixed height to enable scrolling and have sticky table header
    height: "72.2vh",
    width: "100%",
    "overflow-x": "scroll",
  };

  function rowKeyGetter(row) {
    return row.id;
  }

  let dataGridRef = React.createRef();
  function CustomRowRenderer(key, _props) {
    const handleDrop = event => {
      event.preventDefault();
      event.stopPropagation();
      const rowElement = event.target.parentElement;
      if (rowElement) {
        rowElement.classList.remove("drop-valid");
      }

      const files = event.dataTransfer.items || event.dataTransfer.files;
      if (files.length > 1) {
        notifications.warn("Only one file can be uploaded at a time");
      } else {
        try {
          console.log(JSON.stringify(event.dataTransfer.files[0]?.name));
          if (_props.row.sub_category !== "LE") {
            const file = files[0].getAsFile();
            if (file) {
              props.selectCaseFile(_props.row);
              props.uploadCaseFile(file);
            } else {
              notifications.error("Couldn't get a file from drag & drop, please try again");
            }
          } else {
            notifications.warn("Life Expectancy files cannot be uploaded through drag & drop");
          }
        } catch (error) {
          notifications.error("Problem uploading the drag & dropped file, please try again");
        }
      }
    };

    const handleDragEnter = event => {
      const rowElement = event.target.parentElement;
      if (rowElement) {
        if (_props.row.sub_category !== "LE") {
          rowElement.classList.add("drop-valid");
        }
      }
    };

    const handleDragLeave = event => {
      const rowElement = event.target.parentElement;
      if (rowElement) {
        rowElement.classList.remove("drop-valid");
      }
    };

    return (
      <Row
        {..._props}
        id={`row-${_props.rowIdx}`}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      />
    );
  }

  const sortedRows = React.useMemo(() => {
    if (sortColumns.length === 0) return rows;

    const sortedRows = [...rows];
    sortedRows.sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === "ASC" ? compResult : -compResult;
        }
      }
      return 0;
    });
    return sortedRows;
  }, [rows, sortColumns]);

  const MENU_ID = "CaseFiles";
  const { show } = useContextMenu({ id: MENU_ID });

  function handleContextMenu(event) {
    if (!props.readMode) {
      show({ event, props: { key: "value" } });
    }
  }

  const onRowsChange = async (_rows, { indexes, column }) => {
    const rowIndex = indexes[0];
    const changedRow = _rows[rowIndex];
    if (!column) {
      return;
    }
    // Save the row if it has an id
    const changedKey = column.key;
    const changedValue = changedRow[changedKey];
    const caseFileId = changedRow.id;

    if (caseFileId) {
      const originalRow = rows.find(r => r.id === changedRow.id);
      if (originalRow[changedKey] !== changedValue) {
        let data = {};
        data[changedKey] = changedValue;
        setRows(_rows);
        await props.editCaseFile(caseFileId, data, undefined, true);
        notifications.clearAll();
        notifications.success("Row saved");
      } else {
        console.log(originalRow[changedKey], changedValue);
        console.log("Row did not change, skipping update");
      }
    }
  };

  return (
    <div onContextMenu={handleContextMenu}>
      <Menu id={MENU_ID}>
        <Submenu label="Change Follow Up">
          <Item id="followUpNextWeek" onClick={add7FollowUp}>
            Follow Up in 7 days
          </Item>
          <Item
            id="chooseFollowUp"
            onClick={() =>
              props.showModal(CONFIRM_FOLLOW_UP_REPLACE_MODAL, {
                onConfirm: date => chooseFollowUp(date),
              })
            }
          >
            Choose Follow Up Date
          </Item>
        </Submenu>
        <Submenu label="Change Status">
          <Item id="status.NOT_REQUIRED" data={{ status: STATUS.NOT_REQUIRED }} onClick={changeStatus}>
            Not Required
          </Item>
          <Item id="status.NOT_YET_REQUESTED" data={{ status: STATUS.NOT_YET_REQUESTED }} onClick={changeStatus}>
            Not Yet Requested
          </Item>
          <Item id="status.OUTSTANDING" data={{ status: STATUS.OUTSTANDING }} onClick={changeStatus}>
            Outstanding
          </Item>
          <Item id="status.PENDING" data={{ status: STATUS.PENDING }} onClick={changeStatus}>
            Pending
          </Item>
          <Item id="status.RECEIVED_IGO" data={{ status: STATUS.RECEIVED_IGO }} onClick={changeStatus}>
            Received - IGO
          </Item>
          <Item id="status.RECEIVED_NGO" data={{ status: STATUS.RECEIVED_NGO }} onClick={changeStatus}>
            Received - NGO
          </Item>
          <Item
            id="status.RECEIVED_PENDING_REVIEW"
            data={{ status: STATUS.RECEIVED_PENDING_REVIEW }}
            onClick={changeStatus}
          >
            Received - Pending Review
          </Item>
          <Item id="status.WAIVED" data={{ status: STATUS.WAIVED }} onClick={changeStatus}>
            Waived
          </Item>
        </Submenu>
        <Item
          id="delete"
          onClick={() =>
            props.showModal(CONFIRM_FILE_DELETE_MODAL, {
              onConfirm: () => deleteRow(),
            })
          }
        >
          Delete
        </Item>
      </Menu>
      <DataGrid
        ref={dataGridRef}
        rowKeyGetter={rowKeyGetter}
        columns={columns}
        rows={sortedRows}
        onRowsChange={onRowsChange}
        renderers={{ noRowsFallback: <EmptyRowsRenderer />, renderRow: CustomRowRenderer }}
        defaultColumnOptions={{ sortable: true, resizable: true }}
        headerRowHeight={75}
        rowHeight={args => (args.type === "ROW" && args.row.type === "DETAIL" ? 500 : 45)}
        style={style}
        selectedRows={selectedRows}
        onSelectedRowsChange={setSelectedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        onCellContextMenu={({ row }, event) => {
          setRowIdx([row.id]);
        }}
        onCellClick={(args, event) => {
          if (!props.readMode) {
            event.preventGridDefault();
            args.selectCell(true);
          }
        }}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    caseFiles: caseFilesSelector(state),
    insuredList: insuredListSelector(state),
    ownerList: ownerListSelector(state),
    loading: loadingSelector(state),
    loadingCaseFiles: loadingCaseFilesSelector(state),
    userChoices: userChoicesSelector(state),
    hasAdminPermission: isAdmin(state),
    isViewMode: getViewMode(),
    readMode: isReadMode(state),
    isSalesRep: isSalesRep(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  downloadFile,
  selectCaseFile,
  createCaseFile,
  editCaseFile,
  editMultipleCaseFiles,
  deleteMultipleCaseFiles,
  uploadCaseFile,
  fetchCaseFilesByCaseDocumentId,
})(CaseFilesTable);
