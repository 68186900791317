import React from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";
import * as moment from "moment-timezone";

import * as notifications from "../../../../notifications";
import {
  Select,
  DateTimeInput,
  DateInput,
  LPEXFADateTimeInput,
  EditorField,
  BooleanSelect,
  FormCollapsible,
  SubmitButton,
  TextInput,
  CurrencyInput,
} from "components/forms";
import {
  createFunderAuctionActivity,
  editFunderAuctionActivity,
  fetchAuctionRounds,
  showModal,
  selectFunder,
  fetchInsured,
} from "actions";
import {
  activeFunderAuctionActivitySelector,
  auctionRoundsSelector,
  managementChoicesSelector,
  activeCaseSelector,
  auctionIdSelector,
  displayGrossOfferSelector,
  activeAgencySelector,
  fundersSelector,
  agentsChoicesSelector,
} from "reducers";
import { partial, getValue, findObjectByPropertyValue, commaNameToFirstLastName } from "../../../../utils";
import { AUCTION_ACTIVITY_CHANGE_WINNING_BID_MODAL, SEND_FUNDER_EMAIL_MODAL } from "components/modals";
import DateTimeInputWithNowAsDefault from "../../../../components/forms/DateTimeInputWithNowAsDefault";
import {
  activityTypeChoices,
  PREMIUM_PAYMENT_ADVANCE,
  PREMIUM_PAYMENT_REIMBURSEMENT,
  premiumPaymentCategoryChoices,
  REVENUE_MODEL_CHOICES,
} from "../../../../constants";
import { SET_EMAIL_TEMPLATE, SET_SELECTED_FUNDERS, SET_SUGGESTED_CC } from "../../../../actions/types";

const FunderActivityForm = ({ auctionActivity, funderId = null, ...props }) => {
  const {
    round,
    date,
    bid_rescinded_date,
    funder,
    provider,
    type,
    total_offer,
    custom_lr_spread,
    rdb,
    rdb_offer,
    funder_winning_bid,
    premium_payment_category,
    payment_description,
    premium_payment_amount,
    premium_apportionment,
    apportionment_description,
    term_conversion_handling,
    conversion_compensation,
    funder_offer_expiration_date,
    notes,
    revenue_model,
    created_by_name,
    last_edited_by_name,
  } = auctionActivity;

  const display_gross_offer = null;
  const display_lr_spread = null;
  let closing_funder = "";
  const dispatch = useDispatch();

  const updateClosingFunder = (selectedFunder, revenueModel) => {
    if (selectedFunder && revenueModel === "DIRECT") {
      // If Revenue Model is Direct Placement -> Closing Funder is the selected Funder
      closing_funder = selectedFunder.name;
    } else {
      // If Revenue Model is Trading -> Closing Funder is the the Agency's "Balance Sheet Funder"
      let balanceSheetFunderId = props.activeAgency.balance_sheet_funder;
      if (balanceSheetFunderId) {
        const balanceSheetFunder = props.funders.find(f => f.id === balanceSheetFunderId);
        if (balanceSheetFunder) {
          closing_funder = balanceSheetFunder.name;
        }
      }
    }
  };

  React.useEffect(() => {
    // Request the funder if it can't be found in the saved list
    const selectedFunder = props.funders.find(f => f.id === funder);
    if (funder && !selectedFunder) {
      props.selectFunder(funder);
    }
  }, [funder]);

  if (funder) {
    const selectedFunder = props.funders.find(f => f.id === funder);
    updateClosingFunder(selectedFunder, revenue_model);
  }
  let currentDate = moment().tz("America/New_York");

  return (
    <Formik
      enableReinitialize
      initialValues={{
        round,
        date: date || currentDate.format("YYYY-MM-DD[T]HH:mm"),
        bid_rescinded_date,
        funder: funder || funderId,
        provider,
        type,
        total_offer,
        custom_lr_spread,
        display_gross_offer,
        display_lr_spread,
        rdb,
        rdb_offer,
        funder_winning_bid,
        premium_payment_category,
        payment_description,
        premium_payment_amount,
        premium_apportionment,
        term_conversion_handling,
        conversion_compensation,
        apportionment_description,
        funder_offer_expiration_date: funder_offer_expiration_date || currentDate.add(2, "weeks").format("YYYY-MM-DD"),
        notes,
        revenue_model,
        closing_funder,
        created_by_name,
        last_edited_by_name,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isEmpty(auctionActivity)
          ? props.createFunderAuctionActivity
          : partial(props.editFunderAuctionActivity, auctionActivity.id);

        // format date:
        if (values && values.date) {
          values.date = moment(values.date).format("YYYY-MM-DD[T]HH:mm");
        }
        // clear payment amount if deselected
        if (
          values.premium_payment_category !== PREMIUM_PAYMENT_ADVANCE &&
          values.premium_payment_category !== PREMIUM_PAYMENT_REIMBURSEMENT
        ) {
          values.premium_payment_amount = 0;
        }

        try {
          if (props.caseInfo && props.caseInfo.bidding_format === "LPEX") {
            // if LPEX, pass the provider as provider
            values.provider = props.caseInfo.provider;
          }
          if (props.caseInfo && props.caseInfo.bidding_format === "LPEX") {
            // if LPEX, pass the provider as provider
            if (values.funder_winning_bid) {
              values.market_winning_bid = true;
            } else if (values.funder_winning_bid === false) {
              values.market_winning_bid = false;
            }
          }
          const payload = {
            auction_id: props.auctionId,
            bidding_format: props.caseInfo.bidding_format,
            ...values,
          };
          let previousWinningBid = auctionActivity.funder_winning_bid;
          let newWinningBid = values.funder_winning_bid;

          await method(payload);

          let changingWinningBid = newWinningBid && newWinningBid !== previousWinningBid;
          if (changingWinningBid) {
            notifications.warn("New Winning Bid - showing Funder Winning Bid Notification");
            const selectedFunderValues = [values.funder];
            await dispatch({
              type: SET_SELECTED_FUNDERS,
              payload: selectedFunderValues,
            });

            // Add Trading & Closing Managers to the CC list
            let suggestedCCEmails = "";
            const tradingManagerId = props.caseInfo.auction_manager;
            const closingManagerId = props.caseInfo.closing_manager;
            if (tradingManagerId) {
              let tradingManager = findObjectByPropertyValue(props.auctionManagerChoices, "value", tradingManagerId);
              if (tradingManager) {
                let name = commaNameToFirstLastName(tradingManager.label);
                suggestedCCEmails += `${name} <${tradingManager.email}>; `;
              }
            }
            if (closingManagerId) {
              let closingManager = findObjectByPropertyValue(props.closingManagerChoices, "value", closingManagerId);
              if (closingManager) {
                let name = commaNameToFirstLastName(closingManager.label);
                suggestedCCEmails += `${name} <${closingManager.email}>;`;
              }
            }
            await dispatch({
              type: SET_SUGGESTED_CC,
              payload: suggestedCCEmails,
            });

            await dispatch({
              type: SET_EMAIL_TEMPLATE,
              payload: "Funder Winning Bid Notification",
            });
            await props.fetchInsured(props.caseInfo.id);
            props.showModal(SEND_FUNDER_EMAIL_MODAL);
          }

          props.fetchFunderAuctionActivities();
          notifications.success("Activity Detail saved successfully");
          props.fetchAuctionRounds();
        } catch (error) {
          if (error.response.data.funder_winning_bid) {
            props.showModal(AUCTION_ACTIVITY_CHANGE_WINNING_BID_MODAL, {
              activityValues: { ...values, id: auctionActivity.id, mode: "funder" },
            });
          } else {
            setErrors(error.response.data);
            notifications.error("Error editing Activity");
          }
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => {
        const isBid =
          values.type === getValue(activityTypeChoices, "Bid") ||
          values.type === getValue(activityTypeChoices, "Bid Contingent") ||
          values.type === getValue(activityTypeChoices, "Bid Rescinded");
        const isBidRescinded = values.type === getValue(activityTypeChoices, "Bid Rescinded");
        const total_offer_value = values.total_offer;
        const isLPEX = props.caseInfo && props.caseInfo.bidding_format === "LPEX";

        const onFunderChange = o => {
          if (o && o.value) {
            const selectedFunder = props.funders && props.funders.find(f => f.id === o.value);
            if (selectedFunder) {
              let revenueModel = selectedFunder.default_revenue_model || "";
              setFieldValue("revenue_model", revenueModel);
              updateClosingFunder(selectedFunder, revenueModel);
            } else {
              setFieldValue("revenue_model", "");
              closing_funder = "";
            }
          }
        };

        const onRevenueModelChange = o => {
          if (o) {
            const selectedFunder = props.funders.find(f => f.id === values.funder);
            updateClosingFunder(selectedFunder, o.value);
          } else {
            closing_funder = "";
          }
        };

        return (
          <div style={{ minHeight: 420 }}>
            <FormCollapsible onSubmit={handleSubmit} title="Activity Detail">
              <Form.Row>
                <Select
                  label="Funder"
                  name="funder"
                  placeholder="Select Funder"
                  options={props.funderChoices.getChoices({ sortByLabel: true })}
                  onChangeCallback={onFunderChange}
                />
                {isLPEX && isBid ? (
                  <LPEXFADateTimeInput label="Activity Date" name="date" total_offer={total_offer_value} isRequired />
                ) : (
                  <DateTimeInput label="Activity Date" name="date" isRequired />
                )}
                <Select
                  label="Activity Type"
                  name="type"
                  placeholder="Select Activity Type"
                  options={activityTypeChoices}
                  isRequired
                />
              </Form.Row>
              {isBid && (
                <Form.Row>
                  <CurrencyInput label="Total Offer" name="total_offer" isRequired={isBid} />
                  <DateInput label="Offer Expiration" name="funder_offer_expiration_date" isRequired />
                  <BooleanSelect
                    label="Winning Bid"
                    name="funder_winning_bid"
                    isRequired
                    defaultValue={{ value: false, label: "No" }}
                  />
                </Form.Row>
              )}
              {isBidRescinded && (
                <Form.Row>
                  <DateTimeInputWithNowAsDefault label="Bid Rescinded Date" name="bid_rescinded_date" md={4} />
                </Form.Row>
              )}
              {isBid && (
                <>
                  <Form.Row>
                    {isLPEX && <CurrencyInput label="Gross Offer" name="display_gross_offer" disabled />}
                    {isLPEX && <CurrencyInput label="LR Spread" name="display_lr_spread" disabled />}
                  </Form.Row>
                </>
              )}
              {isBid && (
                <Form.Row>
                  <BooleanSelect
                    label="RDB"
                    name="rdb"
                    isRequired
                    defaultValue={{ value: false, label: "No" }}
                    md={4}
                  />
                  {values.rdb === true && <TextInput label="RDB Offer" name="rdb_offer" />}
                </Form.Row>
              )}
              {isBid && (
                <Form.Row>
                  <Select
                    label="Premium Payment Category"
                    name="premium_payment_category"
                    placeholder="None"
                    options={premiumPaymentCategoryChoices}
                    defaultValue={{ value: "NO", label: "No" }}
                    isRequired
                    md={4}
                  />
                  {values.premium_payment_category && values.premium_payment_category !== "NO" && (
                    <TextInput label="Payment Description" name="payment_description" isRequired />
                  )}
                  {(values.premium_payment_category === PREMIUM_PAYMENT_ADVANCE ||
                    values.premium_payment_category === PREMIUM_PAYMENT_REIMBURSEMENT) && (
                    <CurrencyInput label="Payment Amount ($)" name="premium_payment_amount" isRequired />
                  )}
                </Form.Row>
              )}
              {isBid && (
                <Form.Row>
                  <BooleanSelect
                    label="Premium Apportionment"
                    name="premium_apportionment"
                    isRequired
                    defaultValue={{ value: false, label: "No" }}
                    md={4}
                  />
                  {values.premium_apportionment === true && (
                    <TextInput label="Apportionment Description" name="apportionment_description" />
                  )}
                </Form.Row>
              )}
              {isBid && (
                <Form.Row>
                  <BooleanSelect
                    label="Term Conversion Handling"
                    name="term_conversion_handling"
                    isRequired
                    defaultValue={{ value: false, label: "No" }}
                    md={4}
                  />
                  {values.term_conversion_handling === true && (
                    <CurrencyInput label="Conversion Compensation Earned" name="conversion_compensation" />
                  )}
                </Form.Row>
              )}
              {isBid && !isBidRescinded && (
                <Form.Row>
                  <Select
                    label="Revenue Model"
                    name="revenue_model"
                    placeholder="Select Revenue Model"
                    options={REVENUE_MODEL_CHOICES}
                    value={revenue_model}
                    md={4}
                    onChangeCallback={onRevenueModelChange}
                  />
                  <TextInput label="Closing Funder" name="closing_funder" value={closing_funder} disabled md={4} />
                </Form.Row>
              )}
              <Form.Row>
                {created_by_name && <TextInput label="Created by" name="created_by_name" disabled={true} />}
                {last_edited_by_name && <TextInput label="Last edited by" name="last_edited_by_name" disabled={true} />}
              </Form.Row>
              <Form.Row>
                <EditorField label="Notes" name="notes" />
              </Form.Row>
              <SubmitButton />
            </FormCollapsible>
          </div>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const rounds = auctionRoundsSelector(state);
  const roundChoices = rounds.map(round => ({ label: round.number, value: round.id }));
  const choices = managementChoicesSelector(state);
  const agentsChoices = agentsChoicesSelector(state);

  return {
    caseInfo: activeCaseSelector(state),
    funders: fundersSelector(state),
    auctionId: auctionIdSelector(state),
    auctionActivity: activeFunderAuctionActivitySelector(state) || {},
    rounds,
    roundChoices,
    providerChoices: choices.case_providers,
    funderChoices: choices.funders,
    displayGrossOffer: displayGrossOfferSelector(state) || {
      display_lr_spread: null,
      display_gross_offer: null,
    },
    activeAgency: activeAgencySelector(state),
    auctionManagerChoices: agentsChoices.auction_managers.getChoices(),
    closingManagerChoices: agentsChoices.closing_managers.getChoices(),
  };
};

export default connect(mapStateToProps, {
  createFunderAuctionActivity,
  editFunderAuctionActivity,
  showModal,
  fetchAuctionRounds,
  selectFunder,
  fetchInsured,
})(FunderActivityForm);
