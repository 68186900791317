import React from "react";
import { IconContext } from "react-icons";
import { addTooltip } from "./IconLink";

const IconButton = ({ Icon, iconConfig = {}, onClick, tooltip, ...props }) => {
  const style = iconConfig.style || {};
  style.cursor = "pointer";
  iconConfig.style = style;

  const button = (
    <IconContext.Provider value={iconConfig}>
      <Icon onClick={onClick} {...props} />
    </IconContext.Provider>
  );

  if (tooltip) {
    return addTooltip(button, tooltip);
  }
  return button;
};

export default IconButton;
