import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import ReactTooltip from "react-tooltip";
import { FcPlus } from "react-icons/fc";

import IconButton from "components/IconButton";
import { updateLifeExpectancy } from "actions";
import { extractedLifeExpectancyPDFdataSelector, activeLifeExpectancySelector } from "reducers";
import { all, partition } from "../../../utils";
import * as notifications from "../../../notifications";

/**
 * Component for showing Mortality Tables from life expectancy reports.
 *
 * A spreadsheet-like component is used (using react-datasheet). Independently
 * of the le vendor, the first column is expected to be "continuous" (1, 2, 3, ...).
 * If any number is missing, an empty row will be shown to make it visible for the user.
 *
 * The component expects a "validate" function that will receive all rows and must
 * return the same rows with any errors found.
 *
 * Example:
 *  data = [
 *    [ { value: 11 }, { value: 12 }, { value: 13 } ],
 *    [ { value: 21 }, { value: 22 }, { value: 23 } ],
 *  ]
 *  validate(data) => [
 *    [ { value: 11 }, { value: 12 }, { value: 13 } ],
 *    [ { value: 21 }, { value: 22, error: "Expected value 23" }, { value: 23 } ],
 *  ]
 *
 * We assume the report will have 4 columns
 */
const MortalityTable = ({ data, lifeExpectancyId, updateLifeExpectancy, validate, submitRef }) => {
  const [rows, setRows] = React.useState(data);

  React.useEffect(() => {
    const validated = validate(data);
    setRows(validated);
  }, [data]);

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      <Title />
      <SaveButton
        onClick={() => {
          const patchData = {
            mortality_table: fromDataSheetFormat(rows),
          };
          updateLifeExpectancy(lifeExpectancyId, patchData);
          notifications.success("Mortality Table saved successfully");
        }}
        submitRef={submitRef}
      />
      <ReactDataSheet
        data={rows}
        className="shadowed"
        valueRenderer={cell => cell.value}
        cellRenderer={cellRenderer}
        onCellsChanged={(changes, additions = []) => {
          // update current rows (changes)
          const updatedRows = [...rows];
          changes.forEach(({ row, col, value }) => {
            updatedRows[row][col].value = value;
          });

          // add the new rows (additions)
          const COLUMNS = 4;
          const newRows = partition(additions, COLUMNS);

          const allRows = [...updatedRows, ...newRows];
          const validated = validate(allRows);
          setRows(validated);
        }}
      />
      <AddNewRowButton rows={rows} setRows={setRows} />
    </>
  );
};

const Title = () => <h5 style={{ marginTop: "2rem", display: "inline-block" }}>Mortality Table</h5>;

const SaveButton = ({ onClick, submitRef }) => (
  <button
    className="btn"
    style={{
      lineHeight: "1.5rem",
      padding: "0 10px",
      marginLeft: "3rem",
      display: "none",
    }}
    onClick={onClick}
    ref={submitRef}
  >
    Save table
  </button>
);

export const AddNewRowButton = ({ rows, setRows, rowSize = 4 }) => (
  <div style={{ marginTop: "1rem", cursor: "pointer" }}>
    <IconButton
      Icon={FcPlus}
      iconConfig={{ size: "1.5rem" }}
      tooltip={"Add New Row"}
      onClick={() => {
        const newRow = Array.from({ length: rowSize }, () => ({ value: "" }));
        setRows([...rows, newRow]);
      }}
    />
  </div>
);

/* Copied from react-datasheet/blob/master/src/Cell.js
 * Modified to use ReactTooltip to render cell errors
 */
export const cellRenderer = props => {
  const {
    cell,
    row,
    col,
    attributesRenderer,
    className,
    style,
    onMouseDown,
    onMouseOver,
    onDoubleClick,
    onContextMenu,
  } = props;

  const { colSpan, rowSpan } = cell;
  const attributes = attributesRenderer ? attributesRenderer(cell, row, col) : {};

  const cellId = `${row}-${col}`;

  const hasErrors = cell.errors && cell.errors.length > 0;

  const renderCellErrors = () => {
    if (!hasErrors) {
      return null;
    }
    /* eslint handle-callback-err: "off" */
    return (
      <ReactTooltip id={cellId} type="error">
        <ul style={{ margin: 0, marginLeft: "10px", textAlign: "initial" }}>
          {cell.errors.map(e => (
            <li>
              <e.Content />
            </li>
          ))}
        </ul>
      </ReactTooltip>
    );
  };

  return (
    <td
      className={className}
      onMouseDown={onMouseDown}
      onMouseOver={onMouseOver}
      onDoubleClick={onDoubleClick}
      onTouchEnd={onDoubleClick}
      onContextMenu={onContextMenu}
      colSpan={colSpan}
      rowSpan={rowSpan}
      style={style}
      data-tip
      data-for={cellId}
      data-error={hasErrors}
      {...attributes}
    >
      {props.children}
      {renderCellErrors()}
    </td>
  );
};

const mapStateToProps = state => {
  const extractedLifeExpectancyData = extractedLifeExpectancyPDFdataSelector(state);
  const activeLifeExpectancy = activeLifeExpectancySelector(state);

  // Data will be either the extracted mortality table, or the already saved mortality table
  let data = get(extractedLifeExpectancyData, "mortality_table");
  if (!data) {
    data = get(activeLifeExpectancy, "mortality_table");
  }

  return {
    data: toDataSheetFormat(data || []),
    lifeExpectancyId: get(activeLifeExpectancy, "id"),
  };
};

const toDataSheetFormat = rows => {
  if (rows.length === 0 || rows.length === 1) {
    return [];
  }

  let sheetRows = [];

  // Title row, make it read only
  sheetRows.push(
    rows[0].map(title => {
      return { value: title, readOnly: true, className: "title" };
    }),
  );

  for (let i = 1; i < rows.length; i++) {
    let row = rows[i];
    sheetRows.push(row.map(value => ({ value })));
  }

  return sheetRows;
};

const fromDataSheetFormat = rows => {
  // filter out empty rows
  rows = rows.filter(row => !all(row, cell => cell.value === ""));

  // convert rows to numbers
  return rows.map(row =>
    row.map(cell => {
      const value = Number(cell.value) || cell.value;
      return value;
    }),
  );
};

export default connect(mapStateToProps, { updateLifeExpectancy })(MortalityTable);
